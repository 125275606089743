import React from 'react';
import dates from './dates.json';
import Box from '@mui/material/Box';
import lng from '../../utils/language';
// import Alert from '@mui/material/Alert';
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const inputStyle = {
    background: '#FFF',
    padding: '10px 15px',
    backgroundColor: '#120f2e',
    borderRadius: '20px',
    color: '#FFF',
    outline: 'none',
    '&:focus': {
        outline: 'none',
        borderRadius: '20px',
    }
}

const svgStyle = {
    svg: {
        color: 'rgba(255, 255, 255, 1)',
    }
}

const fieldsetStyle = {
    fieldset: {
        borderRadius: '20px',
    }
}

export default function DatePickerSeperate({day, month, year, setDay, setMonth, fieldValue, setYear, index}) {
    const { language } = useSelector((state) => state.language);
    const years = new Date().getFullYear();
    const [success, setSuccess] = React.useState({
        daySuccess: true,
        monthSuccess: true,
        yearSuccess: false
    })
    // const [error, setError] = React.useState({error: false, msg: ''})
    const inputs = {
        dayInputFunc: (e, playerIndex) => {
            const value = e.target.value;
            setDay(value)
            dateOfBirthSet(playerIndex, `${year}-${month}-${value}`);
        },
        monthInputFunc: (e, playerIndex) => {
            const value = e.target.value;
            setMonth(value);
            dateOfBirthSet(playerIndex, `${year}-${value}-${day}`);
        },
        yearInputFunc: (e, playerIndex) => {
            const value = e.target.value;
            if (value > (years-16)) {
                // setError({
                //     error: true,
                //     msg: 'You must be 16 or older to participate'
                // })
                setSuccess({
                    daySuccess: true,
                    monthSuccess: true,
                    yearSuccess: false
                })
                // setTimeout(() => {
                //     setError({
                //         error: false,
                //         msg: ''
                //     })  
                // }, 5000)
            }else{
                // setError({
                //     error: false,
                //     msg: ''
                // })
                setSuccess({
                    daySuccess: true,
                    monthSuccess: true,
                    yearSuccess: true
                })
            }
            setYear(value);
            dateOfBirthSet(playerIndex, `${value}-${month}-${day}`);
        },
    }

    const dateOfBirthSet = (playerIndex, value) => {
        fieldValue('dob', value, playerIndex);
    }

    return (
        <>
            <Box
                sx={{
                    display: 'grid',
                    columnGap: '20px',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                }}
            >
                <Box sx={{ width: 1 }}>
                    <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]['day']}</InputLabel>
                    <FormControl fullWidth sx={{ '.MuiSelect-select': inputStyle }}>
                        <Select
                            sx={[svgStyle, fieldsetStyle, success.daySuccess ? {fieldset: {borderColor: 'rgb(56, 142, 60)'}} : {fieldset: {borderColor: '#d32f2f'}}]}
                            defaultValue={day}
                            onChange={(e) => inputs.dayInputFunc(e, index)}
                        >
                            {
                                dates.days?.map((data, index) => (
                                    <MenuItem key={index} value={data.value}>
                                        {data.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ width: 1 }}>
                    <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]['month']}</InputLabel>
                    <FormControl fullWidth sx={{ '.MuiSelect-select': inputStyle }}>
                        <Select
                            sx={[svgStyle, fieldsetStyle, success.monthSuccess ? {fieldset: {borderColor: 'rgb(56, 142, 60)'}} : {fieldset: {borderColor: '#d32f2f'}}]}
                            defaultValue={month}
                            onChange={(e) => inputs.monthInputFunc(e, index)}
                        >
                            {
                                dates.months[language].map((data, index) => (
                                    <MenuItem key={index} value={data.value}>
                                        {data.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ width: 1 }}>
                    <InputLabel sx={{ fontSize: '12px', paddingLeft: '10px', color: '#FFF' }}>{lng[language]['year']}</InputLabel>
                    <FormControl fullWidth sx={{ '.MuiSelect-select': inputStyle }}>
                        <Select
                            sx={[svgStyle, fieldsetStyle, success.yearSuccess === true ? {fieldset: {borderColor: 'rgb(56, 142, 60)'}} : {fieldset: {borderColor: '#d32f2f'}}]}
                            defaultValue={year}
                            onChange={(e) => inputs.yearInputFunc(e, index)}
                        >
                            {
                                Array.from({ length: years - (years - 50) + 1 }, (_, i) => (years - 50) + i).map((data, index) => (
                                    <MenuItem key={index} value={data}>
                                        {data}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Box>

            </Box>

            {/* {
                error.error &&
                <Alert variant="filled" severity="error" sx={{position: 'fixed', bottom: '30px', left: '30px'}}>
                    {
                        error.msg
                    }
                </Alert>
            } */}
        </>
    )
}
