import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '../../../layout/grid/Grid';
import ImageTextCard from '../../../components/card/ImageTextCard';
import CarouselGallery from '../../../components/carousel/CarouselGallery';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import RadiantBanner from '../../../components/general/banner/RadiantBanner';
import constants from '../../../utils/consts';
import FetchData from '../../../api/FetchData';
import { bussinessRoom } from '../../../utils/gameLng';
import { useSelector } from "react-redux";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Recapcha from '../../../components/recaptcha/Recapcha';

export default function BusniessRoom() {
    const { language } = useSelector((state) => state.language);
    const ref = React.useRef(null);
    const [recatpchaVal, setRecaptchaVal] = React.useState(null);
    const [reserveEvent, setReserveEvent] = React.useState({
        fullName: '',
        company: '',
        email: '',
        phoneNum: '',
        eventType: '',
        requierments: ''
    })

    const [errorReserveEvent, setErrorReserveEvent] = React.useState({
        fullNameError: false,
        companyError: false,
        emailError: false,
        phoneNumError: false,
        eventTypeError: false,
        requiermentsError: false
    })

    const cards = {
        eng: [
            {
                body_header: 'Team Retreats',
                bodyHeaderStyle: {
                    color: '#AF46FA',
                    marginBottom: '20px !important'
                },
                body_desc: `HyperActive is the ultimate destination for your next corporate event or team-building activity! Our facility offers an impressive range of activities, including E-karting, Digital Darts, Laser Tag, Escape Rooms, Bowling, and more. After your adrenaline-pumping experience, indulge in a delicious meal from our range of food brands or unwind with drinks at our Bar. With everything under one roof, we make it easy to plan a day full of team-building fun.`,
                images: 'https://hyper-active.com/images/rooms/party rooms/compressed/TeamRetreats.png',
                fullPath: true
            },
            {
                body_header: 'Conference Room',
                bodyHeaderStyle: {
                    color: '#FAA000',
                    marginBottom: '20px !important'
                },
                body_desc: `Welcome to our corporate rooms, the ideal venue for your professional meetings. Equipped with state-of-the-art technology and comfortable seating, our rooms foster productive discussions. Our dedicated staff ensures seamless meetings, while our Bar offers food and drinks for your refreshment. With a convenient location, professional atmosphere, and exceptional service, our corporate rooms are perfect for your successful meetings. Schedule your next meeting with us to experience comfort, technology, and professionalism combined with delightful dining.`,
                images: 'https://hyper-active.com/images/rooms/party rooms/compressed/ConferenceRoom.png',
                fullPath: true
            },
            {
                body_header: 'Sports Bar',
                bodyHeaderStyle: {
                    color: '#2D8CFF',
                    marginBottom: '20px !important'
                },
                body_desc: `Eat, Drink, Play in the vibrant ambiance of our Sports Bar. 
                
                Explore an array of culinary delights, beverages, and engaging games such as Axe Throwing, Bowling, Shuffleboard, Darts, and Billiards. 
                
                Craft alluring and dynamic combinations to celebrate milestones with your colleagues.`,
                images: 'https://hyper-active.com/images/rooms/party rooms/compressed/SportsBar.png',
                fullPath: true
            },
            {
                body_header: 'Private Party',
                bodyHeaderStyle: {
                    color: '#FAA000',
                    marginBottom: '20px !important'
                },
                body_desc: `Our rooms for private events are meticulously designed to provide a warm and inviting atmosphere for your celebration. 
                
                Choose food and drinks from carefully crafted menus and enjoy the Hyper experience, where our team takes care of every detail!`,
                images: 'https://hyper-active.com/images/rooms/party rooms/compressed/PrivateParty.png',
                fullPath: true
            }
        ],
        alb: [
            {
                body_header: 'Team Retreats',
                bodyHeaderStyle: {
                    color: '#AF46FA',
                    marginBottom: '20px !important'
                },
                body_desc: `Përveç dhomave private, në HyperActive ju presin edhe shumë aktivitete të cilat krijojnë lidhje më të thella mes ekipeve - të gjitha nën një çati! Sfidoni kolegët në pistën tonë të kartingut, testoni se kush është i lindur për të qenë lider përmes Escape Rooms dhe argëtohuni në Bowling, LaserTag etj. Dhe për fund përmbylleni ditën me pije dhe ushqim nga bari ynë.`,
                images: 'https://hyper-active.com/images/rooms/party rooms/compressed/TeamRetreats.png',
                fullPath: true
            },
            {
                body_header: 'Conference Room',
                bodyHeaderStyle: {
                    color: '#FAA000',
                    marginBottom: '20px !important'
                },
                body_desc: `Dhomat tona janë kombinim i ngrohtësisë së mjaftueshme për të krijuar lidhje ndërvepruese mes kolegëve përmes festave private dhe ambientit zyrtar për t'iu përshtatur secilës kërkesë për trajnime dhe konferenca. 
                
                Poashtu, zgjedhni nga shumëllojshmëria e menuve të ushqimit dhe pijeve për shijen e secilit.`,
                images: 'https://hyper-active.com/images/rooms/party rooms/compressed/ConferenceRoom.png',
                fullPath: true
            },
            {
                body_header: 'Sports Bar',
                bodyHeaderStyle: {
                    color: '#2D8CFF',
                    marginBottom: '20px !important'
                },
                body_desc: `Hani, pini dhe luani në ambientin dinamik të Sports Barit. 
                
                Përzgjedhni nga llojet e ndryshme të ushqimit, pijeve dhe lojërave si Axe throwing, Bowling, Shuffleboard, Pikado dhe Biliardo. 
                
                Bëni kombinime tërheqëse dhe dinamike për të përmbyllë vitin dhe për të festuar arritjet me kolegë.`,
                images: 'https://hyper-active.com/images/rooms/party rooms/compressed/SportsBar.png',
                fullPath: true
            },
            {
                body_header: 'Private Party',
                bodyHeaderStyle: {
                    color: '#FAA000',
                    marginBottom: '20px !important'
                },
                body_desc: `Dhomat tona për festat private, janë dizajnuar me përpikmëri për të ofruar ambient të ngrohtë dhe tërheqës për festën tuaj. 
                
                Përzgjedhni ushqim dhe pije nga menutë e përpiluara me kujdes dhe shijoni përvojën Hyper për të cilën kujdeset ekipi ynë rreth çdo detaji!`,
                images: 'https://hyper-active.com/images/rooms/party rooms/compressed/PrivateParty.png',
                fullPath: true
            }
        ]
    }

    const gallery = [
        '../../imageTests/rooms/party rooms/compressed/carouselgallery-1.png',
        '../../imageTests/rooms/party rooms/compressed/carouselgallery-2.png',
        '../../imageTests/rooms/party rooms/compressed/carouselgallery-3.png'
    ]

    const inputs = [
        {
            special: false,
            label: bussinessRoom[language]['fullName'],
            type: 'text',
            multiline: false,
            required: true,
            error: errorReserveEvent.fullNameError,
            value: reserveEvent.fullName,
            change: (e) => { 
                var value = e.target.value;
                if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                    setErrorReserveEvent({ ...errorReserveEvent, fullNameError: true })
                } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value === "") {
                    setErrorReserveEvent({ ...errorReserveEvent, fullNameError: false })
                }
                setReserveEvent({ ...reserveEvent, fullName: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px',
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                }
            },
        },
        {
            special: false,
            label: bussinessRoom[language]['companyName'],
            type: 'text',
            multiline: false,
            required: true,
            error: errorReserveEvent.companyError,
            value: reserveEvent.company,
            change: (e) => { 
                var value = e.target.value;
                if (value !== "" && !value.match(/[\S\s]+[\S]+/)) {
                    setErrorReserveEvent({ ...errorReserveEvent, companyError: true })
                } else if (value.match(/[\S\s]+[\S]+/) || value === "") {
                    setErrorReserveEvent({ ...errorReserveEvent, companyError: false })
                }
                setReserveEvent({ ...reserveEvent, company: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px'
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                }
            },
        },
        {
            special: false,
            label: bussinessRoom[language]['email'],
            type: 'text',
            multiline: false,
            required: true,
            error: errorReserveEvent.emailError,
            value: reserveEvent.email,
            change: (e) => { 
                var value = e.target.value;
                if (value !== "" && !value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/)) {
                    setErrorReserveEvent({ ...errorReserveEvent, emailError: true })
                } else if (value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/) || value === "") {
                    setErrorReserveEvent({ ...errorReserveEvent, emailError: false })
                }
                setReserveEvent({ ...reserveEvent, email: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px',
                    padding: 0
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                }
            },
        },
        {
            special: false,
            label: bussinessRoom[language]['phone'],
            type: 'text',
            multiline: false,
            required: true,
            error: errorReserveEvent.phoneNumError,
            value: reserveEvent.phoneNum,
            change: (e) => { 
                var value = e.target.value;
                if (value !== "" && !value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/)) {
                    setErrorReserveEvent({ ...errorReserveEvent, phoneNumError: true })
                } else if (value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/) || value === "") {
                    setErrorReserveEvent({ ...errorReserveEvent, phoneNumError: false })
                }
                setReserveEvent({ ...reserveEvent, phoneNum: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px'
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                }
            },
        },
        {
            special: true,
            label: bussinessRoom[language]['eventType'],
            type: 'text',
            multiline: false,
            required: true,
            error: errorReserveEvent.eventTypeError,
            value: reserveEvent.eventType,
            change: (e) => { 
                var value = e.target.value;
                if (value !== "" && !value.match(/[\S\s]+[\S]+/)) {
                    setErrorReserveEvent({ ...errorReserveEvent, eventTypeError: true })
                } else if (value.match(/[\S\s]+[\S]+/) || value === "") {
                    setErrorReserveEvent({ ...errorReserveEvent, eventTypeError: false })
                }
                setReserveEvent({ ...reserveEvent, eventType: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px'
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                }
            },
        },
        {
            special: true,
            label: bussinessRoom[language]['request'],
            type: 'text',
            multiline: true,
            minRows: 10,
            required: true,
            error: errorReserveEvent.requiermentsError,
            value: reserveEvent.requierments,
            change: (e) => { 
                var value = e.target.value;
                if (value !== "" && !value.match(/[\S\s]+[\S]+/)) {
                    setErrorReserveEvent({ ...errorReserveEvent, requiermentsError: true })
                } else if (value.match(/[\S\s]+[\S]+/) || value === "") {
                    setErrorReserveEvent({ ...errorReserveEvent, requiermentsError: false })
                }
                setReserveEvent({ ...reserveEvent, requierments: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px'
                },
                '.MuiOutlinedInput-root': {
                    paddingY: '10px'
                }
            },
        },

    ]

    const [success, setSuccess] = React.useState(false);
    const [msg, setMsg] = React.useState({
        type: ''
    })

    const handleSubmit = (e) => {
        e.preventDefault();
        e.preventDefault();
        const validation = Object.values(errorReserveEvent);
        const confirmation = validation.includes(true);
        if (confirmation === false) {
            FetchData.create('/data/events', reserveEvent)
            .then((res) => {
                setSuccess(true)
                setMsg({...msg, type: res.data.type})
                setReserveEvent({
                    fullName: '',
                    company: '',
                    email: '',
                    phoneNum: '',
                    eventType: '',
                    requierments: ''
                })
            })
            .catch(err => {
            })
        }else{
            setSuccess(true)
            setMsg({...msg, type: "error"})
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setSuccess(false);
    };

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
    
    const captchaChange = (val) => {
        setRecaptchaVal(val)
    }

    return (
        <Box>
            <Box
                sx={{
                    display: 'grid',
                    position: 'relative',
                    zIndex: '1',
                    height: '80vh'
                }}
            >
                <Box position={'absolute'} height={1} width={1} top={0} left={0}
                    sx={{
                        position: 'absolute',
                        zIndex: '1',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            display: 'inline-block',
                            width: '100%',
                            height: '100%',
                            marginRight: '5px',
                            boxShadow: '100px 120px 100px 5px rgba(250, 160, 0, 0.2) inset, 100px 150px 150px 5px rgba(240, 60, 68, 0.2) inset',
                            zIndex: 0,
                        }
                    }}
                >
                    <Box component={'img'} src='https://hyper-active.com/images/rooms/party rooms/compressed/Hyperactive_Rooms.png' sx={{ height: 1, width: 1, objectFit: 'cover' }} />
                </Box>
                <Box sx={{ zIndex: 2, height: '90%', alignItems: 'center', display: 'grid', gridTemplateColumns: { sm: '1fr 1fr', xs: '1fr' }, width: { sm: '60%', xs: '90%' }, marginX: 'auto' }}>
                    <Box sx={{ display: 'grid', gridAutoRows: '0fr 0fr 0fr', rowGap: '20px' }}>
                        <Typography variant="h2" sx={{ fontSize: { md: '3.75rem', xs: '40px' } }} fontWeight={800} color={'#FFF'}>{bussinessRoom[language]['mainHeader']}</Typography>
                        <Typography variant="body1" color={'#FFF'}>{bussinessRoom[language]['mainDesc']}</Typography>
                        <Typography className='btn' onClick={handleClick} color={'#FFF'} sx={{ '&:hover': { background: '#FFF', color: '#321eff', borderColor: '#FFF' } }}>{bussinessRoom[language]['mainBtnTxt']}</Typography>
                    </Box>
                </Box>
            </Box>

            <Box className="container bodyWrapper" sx={{ width: { lg: '1024px', xs: '90%' } }}>
                <Grid no={"ReverseSingle"}>
                    {cards[language].map((i, j) => {
                        return <ImageTextCard data={i} key={j} specialStyling={{
                            backgroundColor: 'transparent'
                        }}
                            txtContainerStyling={{
                                marginY: 'auto',
                                '& h1': {
                                    fontSize: '42px',
                                    marginBottom: '0 !important'
                                },
                                '& p': {
                                    fontSize: '14px'
                                }
                            }} />;
                    })}
                </Grid>

                <Box className="container bodyWrapper" sx={{ marginBottom: { md: '150px', xs: '0' } }}>
                    <RadiantBanner
                        header={bussinessRoom[language]['menuHeader']}
                        text={bussinessRoom[language]['menuDesc']}
                        // urlTxt={bussinessRoom[language]['menuBtn']}
                        // url={'/activities/recharge'}
                        imgUrl={constants.imgPath + '../../imageTests/rooms/party rooms/compressed/Steak.png'}
                        textContainer={{
                            '& h1': {
                                fontSize: '44px'
                            }
                        }}
                        imageContainer={{
                            position: {
                                md: 'relative',
                                xs: 'absolute'
                            },
                            '& img': {
                                position: {
                                    md: 'absolute',
                                    xs: 'absolute'
                                },
                                width: {
                                    md: 'unset',
                                    xs: '100%'
                                },
                                height: 'auto'
                            }
                        }}
                    />
                </Box>

                <Box sx={{ marginTop: 10 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Typography variant="h4" color="#FFF" fontWeight={700}>
                            {bussinessRoom[language]['rezNow']}
                        </Typography>
                        <Typography variant="body1" color="#FFF" textAlign={'center'} marginY={2} sx={{ whiteSpace: 'pre-line' }}>
                            {bussinessRoom[language]['contactTeam']}
                        </Typography>
                        <Button variant='contained' sx={{ backgroundColor: '#F03C46', color: '#FFF', borderRadius: '20px', paddingX: 3, paddingY: 1, '&:hover': { backgroundColor: '#F03C46' } }} href="tel:+38349909196">
                            <Typography variant="h6" color="#FFF">{bussinessRoom[language]['callNow']}</Typography>
                        </Button>
                    </Box>
                </Box>

            </Box>

            <Box sx={{ marginTop: { md: '150px', xs: '80px' } }}>
                <CarouselGallery gallery={gallery} radius={5} buttonShow={true} />
            </Box>

            <Box className="container bodyWrapper" sx={{ width: { lg: '1024px', xs: '90%' } }} ref={ref}>
                
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="h4" fontWeight={'bold'} color="#FFF">{bussinessRoom[language]['bookEvents']}</Typography>
                    <Box component={'form'} onSubmit={(e) => handleSubmit(e)}>
                        <Box display={'grid'} marginY={2} columnGap={'30px'} sx={{ gridTemplateColumns: { sm: '1fr 1fr', xs: '1fr' } }}>
                            {
                                inputs.map((data, index) => (
                                    data.special === false &&
                                    <Box key={index} marginY={2}>
                                        <InputLabel shrink sx={{ color: '#FFF', fontSize: '20px' }} >{data.label}</InputLabel>
                                        <TextField
                                            fullWidth
                                            error={data.error}
                                            sx={data.styling}
                                            value={data.value}
                                            variant='outlined'
                                            onChange={e => data.change(e)}
                                            inputProps={{ color: '#000' }}
                                            required
                                        />
                                    </Box>
                                ))
                            }
                        </Box>
                        <Box display={'grid'} gridTemplateColumns={'1fr'} marginY={2} columnGap={'30px'}>
                            {
                                inputs.map((data, index) => (
                                    data.special === true &&
                                    <Box key={index} marginY={2}>
                                        <InputLabel shrink sx={{ color: '#FFF', fontSize: '20px' }} >{data.label}</InputLabel>
                                        <TextField
                                            fullWidth
                                            sx={data.styling}
                                            error={data.error}
                                            multiline={data.multiline}
                                            variant='outlined'
                                            onChange={e => data.change(e)}
                                            inputProps={{ color: '#000' }}
                                            minRows={data.minRows}
                                        />
                                    </Box>
                                ))
                            }
                        </Box>
                        <Box marginY={2}>
                            <Recapcha change={captchaChange} state={recatpchaVal} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button 
                                ype='submit' 
                                variant='contained' 
                                disabled={!recatpchaVal} 
                                sx={{ 
                                    backgroundColor: '#321EFF', 
                                    paddingY: 1.2, 
                                    paddingX: 5, 
                                    color: '#FFF', 
                                    borderRadius: '20px', 
                                    '&.Mui-disabled': {
                                        border: 'solid 1px red'
                                    }, 
                                    '&:hover': { 
                                        backgroundColor: '#FFF', 
                                    '.MuiTypography-body1': {
                                        color: 
                                        '#321EFF'
                                        } 
                                    }
                                }}
                                >
                                <Typography variant="body1" color="#FFF" fontWeight={'500'}>{bussinessRoom[language]['submitBnt']}</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {
                success &&
                <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity={msg.type}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {
                            msg.type === "success" ?
                            language === "alb" ?
                            'Email-i u dërgua me sukses!'
                            :
                            'Email sent succesfully!'
                            :
                            language === "alb" ?
                            'Email-i nuk u dergua ju lutem mbushni te gjitha format siq duhet'
                            :
                            'Email was not sent. Make sure fomr is filled correctly!'
                        }
                    </Alert>
                </Snackbar>
            }
        </Box>
    )
}