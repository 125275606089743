const lng = {
    eng: {

        maxError: 'The maximum number of players has been achieved',

        racingDriverError: 'Please fill in all details for each contestant',
        racingDriverAgeError: 'Contestant must be 16 years of age or older',
        // REGISTER ERRORS START

            registerName: 'Please enter your name',
            registerLastName: 'Please enter your last name',
            registerEmail: 'please enter your email',
            registerMob: 'Please enter your phone number',
            registerPassword: 'Please enter a password',
            registerConfirmPassword: "Your password and Confirm Password don't match",
            registerCity: 'Please enter your city',
            registerCountry: 'Please enter your country',
            registerDay: 'Please select the day your were born',
            registerMonth: 'Please select the month your were born',
            registerYear: 'Please select the year your were born',
            registerGender: 'Please Select Your Gender',

        // REGISTER ERRORS END


        failLogin: 'Your email or password is incorrect',
        day: 'Day',
        month: 'Month',
        year: 'Year',
        // BANNERS
        maxNumber: 'You can only have 5 reservations at once!',
        girlheader: 'Win Arcade Game Tickets',
        girldesc: `- Gather tickets for HyperCard Arcadeadventures
-Convert tickets into exciting rewards
-Stand a chance to win a PlayStation5with just 60,000 tickets placed`,

        boyheader: '1 Hour Of Playtime!',
        boydesc: `Get your card for €10 and dive into 1 hour of thrilling Arcade fun 

This special offer is valid every day on all arcade games featuring the ‘TimePlay’ sign.`,
        //DAYS
        Monday: 'Monday',
        Tuesday: 'Tuesday',
        Wednesday: 'Wednesday',
        Thursday: 'Thursday',
        Friday: 'Friday',
        Saturday: 'Saturday',
        Sunday: 'Sunday',

        raceRules: 'Competition Rules',
        learnMore: 'Read more',
        rezNow: 'Reserve Now',
        homePageLandingTitle: 'HyperActive',
        homePageLandingDesc: 'Welcome to a world of endless entertainment, where adventure awaits you! Discover a universe of games including Karting, Bowling, Escape rooms, Laser tag, VR-Hologate, Axe Throwing, Darts, Shuffleboard, and the perfect venue for your upcoming celebrations.',
        otherActivities: 'Other Activities',
        prepretaionGame: 'How to Prepare for the Game',
        partNow: "Party now!",
        hyperAppHeader: "HyperActive App Coming Soon",
        hyperAppDesc: "View Balance, top up balance, complete all bookings and earn bonuses. All this and more on top of everything very quickly with the HyperActive App.",
        cardAddHeader2: `Giftcard Filled 
    With Adrenaline`,
        cardAddHeader: `Giftcard Filled 
    With Adrenaline`,
        cardAddDesc: `The best gifts are experiences, and with the Hyper Gift Card, you can give your loved ones an adrenaline-packed adventure 
    
    A gift that brings joy to every occasion`,
        rezerveNow: "Book Now!",
        rezerveNowDesc: `We recommend booking for each attraction so you don't have to wait
    in line for an incredible experience.`,
        hyperBirthdayHeader: "Celebrate Your Birthday At HyperActive'",
        hyperBirthdayDescBigSH: "Celebrate your birthday differently, with more adrenaline!",
        hyperBirthdayDescBig: `We have prepared the best packages of various programs for you and your friends, which include the most fun HyperActive attractions.`,
        hyperBirthdayDesc: 'We have prepared the best birthday programs for you and your friends. Book your holiday!',
        hyperBirthdayBtn: 'Book the party',
        gameTypes: "Games",
        gameTypesDesc: "From exclusive titles to well-known favorites, we’ve got something for everyone. So bring your friends and family and let the adventure begin!",
        racesAndEvents: "Races and Events",
        racesAndEventsDesc: "Engage in high-speed excitement with our exhilarating races and exclusive events. Don’t miss our monthly highlights, where we recognize the outstanding Player and Driver of the Month.",
        pickSlot: "Please pick slot time",
        noAvailableSlot: "No available slot!",
        fillData: "Please enter all user info",
        grown: "Grown up",
        grownChild: "Grown up + Children",
        adventure: "Adventure awaits",
        minAge: "The minimum age to race with a responsible person in the two-seater GoKart is 8+.",
        age18: "Age 18+",
        under18: "Persons under the age of 8 cannot play.",
        freeSlots: "Available Slots for",
        policy: "I agree to the company's privacy policy. As we want to keep our customers informed about the services offered by HyperActive, we will occasionally send interesting offers and announcements about other price advantages to your email address.",
        pay: "Pay",
        checkMailConfirm: "Please check your inbox and follow the instructions to verify your account! ",
        activity: "Activity",
        price: "Price",
        pleaseLogin: "Please login or create an account to buy your tickets",
        terms: "I have read and agreed to the Terms and Conditions",
        quantity: "Quantity",
        cart: "Activity Cart",
        activateAcc: "Activate your account",
        playerNo: "Players number",
        click: "Click here",
        priceList: "You can find price list here",
        empty: "Empty Cart",
        player: "Player",
        hurryMessage: "Hurry, the products in your cart are valid for 10 minutes!",
        playerData: "Player Data",
        timeSlot: "Time Slot",
        payment: "Payment",
        proceedCheckout: "Proceed to checkout",
        bookOther: "Book Other",
        name: "First Name",
        lastname: "Last Name",
        fullname: "Full Name",
        email: "Email",
        password: "Password",
        dob: "Date of Birth",
        mob: "Phone Number",
        required: "Please fill out this field!",
        invalidCoupon: "Invalid Coupon!",
        invalid: "Please enter a valid value",
        short: "This value is to short!",
        long: "This value is to long!",
        invalidMail: "Email address is not valid!",
        invalidPhone: "Phone number is not valid!",
        strongPassword: "Please enter a strong password!",
        login: "Login",
        logout: "Logout",
        reservation: "My reservation",
        profile: "Profile",
        register: "Register",
        forgotPsw: "Forgot Password?",
        noAccount: "You don't have an account?",
        doNotMatch: "Passwords must match!",
        acceptTerms: "Please accept our terms & conditions!",
        passwordConfirmation: "Password Confirmation",
        personalNo: "ID Number",
        city: "City",
        next: "Next",
        country: "Country",
        optionsError: "Please select one of the options!",
        verifyMail: "Verify your email",
        verifyMailToRegister:
            "You will need to verify your email to complete registration!",
        successVerification: "Activation was successful!",
        errorVerification: "Sorry, this link is no longer valid",
        errorVerificationMessage:
            "Oops! The email verification link is not valid anymore!",
        successVerificationMessage: "Your account was activated successfully!",
        mailExists: "This Email Address is already used",
        phoneExists: "This Phone Number is already used",
        resendVerification: "Resend the link",
        contactSupport: "Contact Support!",
        sendLink: "Send link",
        linkSuccess: "Link send successfully. Please check your email address!",
        writeMail: "Please enter your email",
        changePassword: "Change Password",
        forgetPasswordError:
            "This user does not have an account or is not verified!",
        verifyAccount: "Please check your email address to activate your account!",
        change: "Save Changes",
        gender: "Gender",
        passwordChanges: "Fjalëkalimi u ndryshua me sukses!",
        // GENERAL
        checkResults: "Check results",
        bookNow: "Book Now",
        seePrices: "You can see our price list",
        seePriceLink: " HERE",
        gameRules: "Safety First",
        gameSuggestion: "Other game suggestions",
        openModal: "Open",
        closeModal: "Close",
        watchVideo: "Watch Video",
        showMore: "Show more",
        haveAcc: 'Already have an account log in',
        showLess: "Show less",
        homepageKarting: `Racers get ready for full adrenaline experiences!

    Experience professional level driving on the 500m indoor track with the all-electric Gokart-RSX2, safe and with impressive performance⚡️
    
    Adventure awaits you!`,
        otherLoginTerms: "By logging in with Google or Facebook you are agreeing with the Terms and Conditions of HyperActive and the company's privacy policy.",
        googleBtn: "Sign in with Google",
        facebookBtn: "Sign in with Facebook",
        matchRequestError: 'Something went wrong! Try again later.',
        matchRequestSuccess: 'Reservation has been made successfully.',
        // INTERES BOXES

        //GAME TRANSLATE
        kartingRules: [
            { num: "1", desc: "ENG" },
            { num: "2", desc: "ENG" },
            { num: "3", desc: "ENG" },
            { num: "4", desc: "ENG" },
            { num: "5", desc: "ENG" },
            { num: "6", desc: "ENG" },
            { num: "7", desc: "ENG" },
            { num: "8", desc: "ENG" },
            { num: "9", desc: "ENG" },
            { num: "10", desc: "ENG" },
            { num: "11", desc: "ENG" },
            { num: "12", desc: "ENG" },
            { num: "13", desc: "ENG" },
            { num: "14", desc: "ENG" },
        ],

        //LASER TAG
        laserTagBannerHeader:
            "What's Laser Tag? Imagine a thrilling live-action video game. If you're looking for a dose of adrenaline, look no further. The arena is filled with obstacles that add intensity and make every game different. Is a game of strategy. You have to be smart, fast and focused if you want to survive! Take your friends and put your survival instincts to the test as you try to outshine your opponents in laser tag.",
        // ARCADE
        arcadeBanner: `Kthehuni mbrapa n’kohë dhe rijetoni epokën e artë të arkadave! Koleksioni ynë i makinerive
    arcade është i mbushur plot nostalgji me lojërat klasike si dhe risitë më të fundit për lojtarë e të
    gjitha moshave. Nga titujt ekskluzivë të filmave të preferuar deri te lojërat e fëmirisë, ka diçka
    për të gjithë.
    Kshuqë mbledhe shoqninë e familjen tuaj dhe le t’fillojë aventura!`,
        arcadeBannerHeader: "",
        arcadeGameRules: "",
        // VR
        VRBannerHeader: "",
        VRGameRules: "",

        vrGames: [
            {
                gameName: "RevolVR 3",
                title: `Ages 12+ 
          Duck, squat and jump to show off your shooting skills in this competitive multiplayer VR cover-shooter`,
                desc: `In a lawless Wild West dystopia, the revolver is the only weapon citizens are allowed. Weapons tycoons start renting robot bodies to humans with hotshot ambitions in return for trying out their newly designed revolvers and participating in grand frontier-like stand-offs. 

          You are now invited to join this blood-lusty crowd.`,
            },
            {
                gameName: "Ragnaröck",
                title: `Ages 12+
          Beat the rhythm to win the Viking race!`,
                desc: `Enter the mystical world of the Vikings in this power-packed competitive multiplayer game. Ragnaröck is easy to play and hard to master. As captain of a Viking ship, all you need is rhythm to get moving and lead your crew to the win. Show off your skills, beat the high score, and defeat family and friends in this stylized race.`,
            },
            {
                gameName: "Ghostbusters VR Academy",
                title: `Become a Ghostbuster in new location-based Virtual Reality Experience launching in over 400 HOLOGATE locations globally.`,
                desc: `For the first time ever, players will be able to train to be a real Ghostbuster in an amazing academy setting and race a new flying version of the ECTO using HOLOGATE’s vehicle motion simulator. This fantasy fulfilling experience is only possible in virtual reality and with HOLOGATE’s location-based entertainment technology.`,
            },
            {
                gameName: "ANGRY BIRDS",
                title: `Ages 6+
          IT’S UP TO YOU AND THREE OF YOUR BIRD BUDDIES TO STOP THEM FROM TURNING YOUR IDYLLIC HOME INTO A MESSY, SMELLY… PIGSTY!`,
                desc: `The award-winning location-based VR platform, along with Sony Pictures Virtual Reality (SPVR) and Rovio Entertainment, brings you a brand new gaming experience. Up to four players can immerse themselves in a first-person VR world where the only thing standing between hordes of bad piggies and Bird Island is you… and your GIANT SLINGSHOT!`,
            },
            {
                gameName: "ZOMBYTE -2-0",
                title: `Ages 16+
          Take the fight back and infiltrate the Zombie stronghold in the subway tunnels.`,
                desc: `The Zombyte virus has mutated and spread into even more terrifying zombie classifications. ZOMBYTE 2 promises to have even more grabbing, biting and slashing. Grab your Z.W.A.T. team, head underground and watch your back as you move from station to station. Use power-ups and cover to make your way through a new batch of the most unimaginable ghastly zombies.`,
            },
            {
                gameName: "SIGVRIED",
                title: `Ages 12+ 
          In an alternative future after the Ragnarök events, brave adventurers explore the destroyed, gigantic fortress of the gods, Vahalla.`,
                desc: `In order to possess the lost treasures of the Nibelungs, players must work as a team to overcome puzzles and traps, battle monsters and dragons, and avoid additional challenges all around. The game combines classic role-playing gameplay, an escape room puzzle element, all happening inside a highly immersive and interactive sci-fi fantasy adventure.`,
            },
            {
                gameName: "CYBERPUNK",
                title: `It’s the beginning of the 22nd century. Society has collapsed amid rapid technological progress.`,
                desc: `People are modifying their bodies extensively. Corporations are at war with one another over data, which has become the most valuable commodity on the market. Your group, made up of cyborgs equipped with special skills, has decided to steal some rare data from the archives of an influential corporation. Infiltrating the complex unnoticed, you must get to the archives and download the stack memory. But there won’t be anything easy about that.`,
            },
            {
                gameName: "The Prison",
                title: `Ages 12+ 
          Your private police force has been cracking down on criminal gangs for years and taking no prisoners.`,
                desc: `The last major operation left several corrupt police heads rolling. But glory is just one step from oblivion. You’ve been framed and put in jail on trumped-up charges. Your friends still have their freedom though. The escape plan is all set, and now you’ll need all the courage, all the cunning, and all the luck you have to get to freedom and clear your name. And to carry on your fight for justice.`,
            },
            {
                gameName: "Blasters of the Universe",
                title: `Grab your friends and try to survive wave after wave of bullets and baddies!`,
                desc: `Blasters of the Universe: VR Bullet Hell is a retro-inspired cooperative multiplayer game that pushes the bullet hell genre to dazzling new heights, pitting you and your friends against wave after wave of bullets and baddies in a neon-infused virtual reality landscape.`,
            },
            {
                gameName: "Mission Sigma",
                title: `Ages 12+
          Can you get past all the traps and preempt a nuclear attack?`,
                desc: `This is where the good news ends. It turns out that on the roof of an abandoned high-rise the maniac has installed a nuclear warhead with a timer on it. The building itself has been turned into a tower filled with intricate traps and obstacles. You have been recommended as a specialist in such puzzles, and the secret service has helped you infiltrate the courtyard. The rest is down to you.`,
            },
            {
                gameName: "SLUGTERRA",
                title: `Ages 12+
          Team up and enter the competitive arena of the SLUGTERRA universe!`,
                desc: `Choose from Eli, the ‘All-Rounder’, with his combination of offensive fire and electrical slugs; Trixie, the ‘Tactician, whose slugs slow opponents down and penetrate through cover; Pronto, the ‘Rogue’, whose jetpack lets him move quickly while he smokes players with fart clouds or goos them with acidic slime; or Kord, the “Tank”, who moves more slowly, but can hammer his opponents with his Rammstone and Sticky Grenuke slugs.`,
            },
            {
                gameName: "Captain Cook",
                title: `Ages 6+
          Family-friendly and cooperative cooking game.`,
                desc: `Captain Cook is a fun, family-friendly, and cooperative cooking game where you and your friends will need to cut, grill and serve the meals requested by the queue of hungry pirates before time runs out. With its wacky pirates and frantic gameplay, Captain Cook is sure to be a hit with players of all ages and skill levels.`,
            },
            {
                gameName: "Groove Guardians",
                title: `Ages 6+
          Electrifying, full body, rhythm and dance, beat-battle game!`,
                desc: `GROOVE GUARDIANS combined with HOLOGATE’s open-air layout, interactive lighting, and gameplay displays create the first-ever virtual reality multiplayer dance floor which open up unprecedented and outrageously fun party concepts for every entertainment location.`,
            },
            {
                gameName: "Zombyte",
                title: `Ages 16+
          Power Plant - Heart pounding, post apocalyptic, Zombie onslaught!`,
                desc: `Drawing upon the company´s long history in high-end cinematic visual effects for feature films, real locations were digitized using the latest laser scanning and photogrammetry technology to achieve an unparalleled state of realism. The end result is a terrifying, heart-pounding, first-hand zombie apocalypse onslaught that has its creep factor ratcheted up from the highly detailed gaming setting of an abandoned, decrepit, industrial warehouse.`,
            },
            {
                gameName: "Cold Clash",
                title: `Ages 6+
          Experience a snowball fight like never before.`,
                desc: `Maneuver your hovercraft around on the frozen lake to collect more golden coins than your opponents, but it isn’t that simple. You must duck, bob, and weave to escape the flying snowballs coming from the other players and a crazy, territorial yeti.`,
            },
            {
                gameName: "Towertag",
                title: `Ages 6+
          The global eSports phenomenon.`,
                desc: `In Tower Tag you will find one of the most anticipated multiplayer experiences in the world, and for those of you longing for a true esports game for your HOLOGATE, look no further.

          Accommodating up to 8 players, in multiple game modes, Tower Tag is a perfect match for the HOLOGATE ARENA.`,
            },
            {
                gameName: "Simurai",
                title: `Ages 12+
          Defend a futuristic base on the home planet of the SIMURAI`,
                desc: `Teleport yourself to higher ground and “power-up” to gain a strategic advantage to eliminate your opponent before they eliminate you. This game is designed to give you the ability to organize tournaments to engage beginners, advanced players and spectators, alike. The gameplay is physically engaging, and easy to learn but hard to master.`,
            },
            {
                gameName: "Simurai Arena",
                title: `Ages 12+
          You and your teammate must claim towers in the arena, while preventing your opponent from gaining too much territory.`,
                desc: `This game is designed to give you the ability to organize tournaments to engage beginners, advanced players and spectators, alike. The gameplay is physically engaging, and easy to learn but hard to master.`,
            },
            {
                gameName: "Das Boot",
                title: `Ages 6+ 
          A thrilling, cooperative, submarine experience, exclusively for HOLOGATE`,
                desc: `In DAS BOOT VR ESCAPE four players work co-operatively to take control of a German u-boat on a deadly mission inspired by the critically acclaimed anti-war movie and Sky TV series. On the mission to intercept allied freighters in the Atlantic, you and a friendly submarine are ambushed by British destroyers.`,
            },
            {
                gameName: "Rig Rebels",
                title: `Ages 12+ 
          Raid a futuristic facility in this action-packed PvP shoot-fest.`,
                desc: `Where players can use their hands to move around the highly-detailed, steampunk environment. Great for experienced gamers or customers looking for a competitive challenge, Rig Rebels requires players to split their focus between shooting, collecting resources, shields and power-ups, as well as movement and position.`,
            },
            {
                gameName: "Hyper GP",
                title: `Ages 6+ 
          Pilot futuristic hover vehicles in fast-paced, multiplayer races!`,
                desc: `The anti-gravity particle has been discovered and harnessed. Humanity now has the technology to establish colonies on the moon, and free energy is no longer a dream… But it has also created hover vehicles which go really, really, fast! Pilot futuristic hover vehicles around floating islands, across desert wastelands, and through giant ice caves in a fast-paced multiplayer race to the finish line! Introducing the first game for the HOLOGATE BLITZ, this is HYPER GP!`,
            },
        ],

        vrRules: [
            { num: "1", desc: "Personat nën moshën 12 vjeç nuk mund të luajnë." },
            {
                num: "2",
                desc: "Seanca e rezervuar do të skadojë nëse nuk arrini para kohës së fillimit të lojës.",
            },
            {
                num: "3",
                desc: "Klientët nuk duhet të luajnë kur ai ose ajo është i lodhur, ka nevojë për gjumë, është nën ndikimin e alkoolit ose drogës,ose vuan nga stresi emocional ose epilepsia.",
            },
            {
                num: "4",
                desc: "Nëse një klient përjeton ndonjë nga simptomat e mëposhtme: marramendje, shikim të paqartë, shtrëngime të syve ose muskujve, humbje të vetëdijes, çorientim gjatë lojës, ai ose ajo duhet të ndërpresë menjëherë lojën.",
            },
            {
                num: "5",
                desc: "Ndalohet përdorimi i telefonit nga personi që është në lojë.",
            },
            { num: "6", desc: "Ndalohet pirja e duhanit/cigareve elektronike." },
            { num: "7", desc: "Nuk lejohet ushqim apo pije në zonën e lojës." },
            {
                num: "8",
                desc: "Ndalohet prekja e pajisjeve pa udhëzimin e personit përgjegjës në Hologate Arena. ",
            },
        ],

        // AXE THROWING
        axeThrowingBannerHeader: "",
        axeThrowingGameRules: "",

        axeRules: [
            {
                num: "1",
                desc: "Të gjithë klientat duhet të nëshkruajnë dokumentin që pranon kushtet & rreziqet e lojës dhe mban përgjegjësi mbi vete.",
            },
            { num: "2", desc: "Personat nën moshën 18 vjeç nuk mund të luajnë." },
            {
                num: "3",
                desc: "Seanca e rezervuar do të skadojë nëse nuk arrini përpara kohës së fillimit të garës.",
            },
            {
                num: "4",
                desc: "Klientët nuk duhet të luajnë kur ai ose ajo është i lodhur, ka nevojë për gjumë, është nën ndikimin e alkoolit ose drogës,ose vuan nga stresi emocional ose epilepsia.",
            },
            {
                num: "5",
                desc: "Nëse një klient përjeton ndonjë nga simptomat e mëposhtme: marramendje, shikim të paqartë, shtrëngime të syve ose muskujve, humbje të vetëdijes, çorientim gjatë lojës, ai ose ajo duhet të ndërpresë menjëherë lojën.",
            },
            {
                num: "6",
                desc: "Këpucët e mbyllura janë të detyrueshme për secilin person që hyn në zonën e hedhjes.",
            },
            {
                num: "7",
                desc: "Veshjet si kapelat, takat, shalli dhe aksesorët mund të krijojnë probleme duke kufizuar lëvizjen gjatë lojës.",
            },
            {
                num: "8",
                desc: "Asnjëherë mos hyni në zonën e hedhjes derisa personi pëgjegjës të japë leje.",
            },
            {
                num: "9",
                desc: "Asnjëherë mos e kaloni vijën e hedhjes derisa sëpatat të jenë në objektiv ose në dysheme.",
            },
            {
                num: "10",
                desc: "Ndalohen ushqimi dhe pijet brenda zonës së hedhjes.",
            },
            { num: "11", desc: "Ndalohet pirja e duhanit/cigareve elektronike." },
            {
                num: "12",
                desc: "Ndalohet përdorimi i telefonit brenda zonës së hedhjes.",
            },
        ],

        // ESCAPE ROOM
        escapeRoomBannerHeader: `CAN YOU ESCAPE?

      Escape Rooms are an adrenaline-filled adventure that throws participants directly into the action with a mission to solve puzzles and challenges. You and your team are transported to a new world where you must race against the clock to beat the game, racing through multiple rooms to find clues and solve challenging puzzles.
      
      Experience a taste of adventure in your hometown with our immersive Escape Rooms, designed to challenge your mind and put your mystery-solving skills to the test:
      
      La Mision Del Profesor, The Haunted Mansion, One Thousand and One Nights
      School of Magic
      `,
        //GAME RULES
        escapeRules: [
            { num: "1", desc: "Personat nën moshën 10 vjeç nuk mund të luajnë." },
            {
                num: "2",
                desc: "Seanca e rezervuar do të skadojë nëse nuk arrini para kohës së fillimit të lojës.",
            },
            {
                num: "3",
                desc: "Gjerat personale(Çantë,xhaketë dhe të tjera) duhet të vendosen në dollap tek recepsioni.",
            },
            { num: "4", desc: "Ndalohet përdorimi i telefonit." },
            { num: "5", desc: "Ndalohet pirja e duhanit/cigareve elektronike." },
            { num: "6", desc: "Nuk lejohet ushqim apo pije brenda dhomës." },
            {
                num: "7",
                desc: "Nuk lejohet të kërceni apo të vraponi brenda hapësirave.",
            },
            {
                num: "8",
                desc: "Ndalohet prekja e dekoreve dhe gjërave të cilat kanë mbishkrimin “DO NOT TOUCH’’.",
            },
            {
                num: "9",
                desc: "Gjërat që ju përdorni gjatë lojës,sigurohuni që pa dashje të mos i merrni me Vete.",
            },
        ],

        // ESCAPE GAMES
        escapeGames: [
            {
                gameName: "LA MISIÓN DEL PROFESOR",
                title: ``,
                desc: `Be a member of the famous crew, to complete the Money Heist, Robbing the National Mint.
          Avoid the security , and print as much money as you can.
    
          The time for the biggest robbery of all time has reached, so put your masks and uniforms and show what you are capable of doing.
          The plan is thoroughly designed and the money awaits you, but when the Mint doors are closed,
          your team will be tested as never again.
    
          Will you be able to remain united under stifling pressure and difficult choices, or will you break and turn against each other?
          The Profesor's plan has already been put into practice, and the greatest danger is yourself.`,
            },
            {
                gameName: "SCHOOL OF MAGIC",
                title: ``,
                desc: `An escape game adventure with extremely technologically advanced features and games, plenty of wow factors and visual effects that create a unique real-life experience.
      
          The Dark One has awoken. Only the power of the Enchanted Scarlet Gem, hidden inside a secret chamber full of dangerous spells and perilous witchcrafts, can put an end to his reign of terror. You and your team of warlocks and witches must retrieve the Gem and defeat the rising darkness, or all will be lost. Make haste, for time is not on your side.`,
            },
            {
                gameName: "ONE THOUSAND & ONE NIGHTS",
                title: ``,
                desc: `Be part of a cinematic experience, exploring the secrets of a kingdom of the East.

          You, brave inhabitants of Abbasid, now is the time to show your loyalty and save our caliphate from the Dark Khalif and his evil plan. He already succeeds conquer the neighbour Caliphates and exile our Sultan. His next step is to destroy our land and our people, dominate the area and create a new Caliphate that will be ruled under the power of fear. You are the only one who can resist and defeat him and save our land from this upcoming disaster.`,
            },
            {
                gameName: "HAUNTED MANSION",
                title: ``,
                desc: `An innovative horror room with intensive & unique atmosphere.
          Combination of visual, sound & jump-scare effects, offer players an ultimate gaming experience.
          
          You are the most experienced “investigators of the supernatural”. Your team was approached by a mysterious man who offered you a huge amount of money, to get inside a specific Mansion and search for a powerful ancient Artifact. He artfully avoided informing you about all the previous investigators he had hired, that never came back...`,
            },
        ],

        // ESCAPE GAME HAINTED HOUSE
        escapeRules2: [
            {
                num: "1",
                type: "Mosha e Lejuar",
                desc: `Mosha minimale për të luajtur është 15+.`,
            },
            {
                num: "2",
                type: "Kush nuk duhet të luaj.",
                desc: `Personat shtatzënë, personat që kanë klaustrofobi dhe personat që vuajnë nga ndonjë sëmundje e zemrës,epilepsi, apo ndonjë sëmundje tjetër nuk lejohet të luajnë.`,
            },
            {
                num: "3",
                type: "Rezervimi",
                desc: `Seanca e rezervuar do të skadojë nëse nuk arrini përpara kohës së fillimit të lojës.`,
            },
            {
                num: "4",
                type: "Gjërat personale",
                desc: `Gjerat personale(Çantë,xhaketë dhe të tjera) duhet të vendosen në dollap tek recepsioni.`,
            },
            {
                num: "5",
                type: "Paisjet elektronike dhe ushqimi",
                desc: `Mosha minimale për të luajtur është 15+.`,
            },
            {
                num: "6",
                type: "Brenda Dhomave",
                desc: `Nuk lejohet të kërceni apo të vraponi brenda hapësirave.`,
            },
            {
                num: "7",
                type: "Paisjet e Escape Rooms",
                desc: `Ndalohet prekja e dekoreve dhe gjërave të cilat kanë mbishkrimin “DO NOT TOUCH’’, në rast dëmtimi gjatë lojës, klientet duhet të paguajnë dëmin e shkaktuar. Gjërat që ju përdorni gjatë lojës,sigurohuni që pa dashje të mos i merrni me vete.`,
            },
        ],

        // BOWLING
        bowlingBannerHeader: "",
        bowlingGameRules: "",
        // LODRA
        lodraBannerHeader: "",
        lodraGameRules: "",
    },
    alb: {
        racingDriverError: 'Ju lutemi të plotësoni të gjitha të dhënat për çdo garues',
        racingDriverAgeError: 'Mosha e garuesit duhet të jetë mbi 16 vjeç ose 16 vjeq',
        maxError: 'Është arritur numri maksimal i lojtarëve',
        failLogin: 'Email-i ose Fjalkalimi juaj është gabimë',
        day: 'Dita',
        month: 'Muaji',
        year: 'Viti',
        maxNumber: 'Mundeni të bëni vetëm 5 rezervime njëkohësisht!',
        // BANNERS
        girlheader: '🎁Fito tiketa tu lujt Arcades🎁',
        girldesc: `🎟 Mbledh tiketa prej lojërave Arcade në HyperCard
🎟 Kthej tiketat në shpërblime
🎟 Fito PlayStation5 me 60.000 tiketa të mbledhura`,

        boyheader: '1 Orë Lojë',
        boydesc: `Mushe kartelën 10€, luj 1 orë Arcade 🤩
Oferta vlen çdo ditë në t'gjitha lojërat arcade që kan shenjën "TimePlay"🕺`,

        //DAYS
        Monday: 'E Hënë',
        Tuesday: 'E Martë',
        Wednesday: 'E Mërkurë',
        Thursday: 'E Enjte',
        Friday: 'E Premte',
        Saturday: 'E Shtunë',
        Sunday: 'E Diel',

        raceRules: 'Rregullat E Garës',
        learnMore: 'Lexo më shumë',
        rezNow: 'Rezervo Tani',
        homePageLandingTitle: 'HyperActive, vendi i aventurave.',
        homePageLandingDesc: 'Mirësevini në botën e argëtimit të pafund, ku ju presin aventura dhe emocione! Zbuloni një univers lojrash nga karting, bowling, escape rooms, lasertag, VR-Hologate, Sakica, Pikado, shuffleboard dhe vendin për festat e juaja të ardhshme.',
        otherActivities: 'Aktivitete të tjera',
        prepretaionGame: 'Si Të Pregaditeni Për Lojën',
        partNow: "Festo tani!",
        hyperAppHeader: "HyperActive App Coming Soon",
        hyperAppDesc: "Shikoni Bilancin, mbushni bilancin, kryeni të gjitha rezervimet dhe fitoni bonuse. Të gjitha këto dhe më shumë në maje të gjishtit shumë shpejtë me HyperActive App.",
        cardAddHeader2: `Dhuratë E Mbushun 
    Me Adrenalinë`,
        cardAddHeader: `Dhuratë E Mbushun 
    Me Adrenalinë`,
        cardAddDesc: `Dhuratat më të mira janë eksperiencat, e me Hyper Gift Card ju mund ti dhuroni personit të dashur nje ekperience plot adrenalinë🚀 
    
    Dhuratë që ju bën të lumtur çdo event.`,
        rezerveNow: "Rezervoni Tani!",
        rezerveNowDesc: `Ju rekomandojmë rezervimin për çdo atraksion që të mos pritni
    në rend për një përvojë të jashtëzakonshme.`,
        hyperBirthdayHeader: 'Festoje Ditëlindjen N’HyperActive',
        hyperBirthdayDesc: 'Ne kemi përgatitur programet më të mira të ditëlindjeve për ju dhe miqtë tuaj. Rezervoni festën tuaj!',
        hyperBirthdayDescBigSH: "Festo ditëlindjen ndryshe, me më shumë adrenalinë! ",
        hyperBirthdayDescBig: `Ne kemi përgatitur paketat më të mira të programeve të ndryshme për ju dhe miqtë tuaj, të cilat përfshijnë atraksionet më argëtuese HyperActive.`,
        hyperBirthdayBtn: 'Rezervo festën',
        gameTypes: "Lojërat",
        gameTypesDesc: "Nga titujt ekskluzivë të filmave të preferuar deri te lojërat e fëmirisë, ka diçka për të gjithë.",
        racesAndEvents: "Garat dhe Eventet",
        racesAndEventsDesc: "Ne kemi përgatitur evente garash dhe ngjarje si lojtari më i mirë i muajit dhe shoferja më e mire e muajit.",
        haveAcc: 'Kthehu mbrapa',
        noAvailableSlot: "Nuk ka slote aktive!",
        invalidCoupon: "Kodi nuk është valid!",
        pickSlot: "Ju lutem zgjedhni slotin",
        player: "Lojtari",
        fillData: "Ju lutem shënoni të gjith detajet e kërkuara",
        under18: "Personat nën moshën 8 vjeç nuk mund të luajnë.",
        grown: "I rritur",
        age18: "Mosha +18 vjeç",
        minAge: "Mosha minimale për të garuar së bashku me një person përgjegjës në GoKart me dy ulëse është 8+ vjeç.",
        grownChild: "I Rritur + Fëmijë",
        terms: "Unë pranoj termat dhe kushtet e përgjithshme të HyperActive!",
        checkMailConfirm: "Ju lutem kontrolloni email adresën tuaj për të konfirmuar llogarinë! ",
        policy: "Jam dakord me politikën e privatësisë së kompanisë. Duke          qenë se ne duam t'i mbajmë klientët tanë të informuar për  shërbimet e ofruara nga HyperActive, ne do të dërgojmë  herë pas here oferta dhe njoftime interesante në lidhje me   avantazhet e tjera të çmimeve në adresën tuaj të postës   elektronike.",
        proceedCheckout: "Vazhdo tek pagesa",
        hurryMessage: "Nxitoni, produktet në shportën tuaj janë të vlefshme për 10 minuta!",
        next: "Vazhdo",
        activity: "Atraksioni",
        price: "Cmimi",
        quantity: "Sasia",
        pay: "Paguaj",
        pleaseLogin: "Ju lutem kyçuni ose regjistrohuni për të rezervuar biletat!",
        bookOther: "Rezervo tjetër aktivitet",
        freeSlots: "Slote te lira për",
        empty: "Shporta e zbrazët",
        cart: "Shporta e Rezervimeve",
        adventure: "Aventura ju pret!",
        activateAcc: "Aktivizo llogarinë",
        playerNo: "Numri i pjesëmarrësve",

        playerData: "Të dhënat e lojtarëve",
        timeSlot: "Sloti kohor",
        payment: "Pagesa",

        click: "Kliko këtu",
        priceList: "Listën e cmimeve mund ta gjeni këtu",
        passwordChanges: "Fjalëkalimi u ndryshua me sukses!",
        name: "Emri",
        lastname: "Mbiemri",
        fullname: "Emri i plotë",
        email: "Email Adresa",
        password: "Fjalëkalimi",
        dob: "Data e lindjes",
        mob: "Numri kontaktues",
        required: "Kjo fushë është e domosdoshme!",
        short: "Vlera duhet të jetë më e gjatë",
        invalid: "Vlera e shënuar nuk është valide!",
        long: "Vlera duhet të jetë max.50 karaktere! ",
        invalidMail: "Email Adresa nuk është valide!",
        invalidPhone: "Numri kontaktues nuk është valid!",
        strongPassword: "Shënoni një fjalëkalim më të sigurtë!",
        login: "Kyçu",
        logout: "Dil",
        profile: "Profili",
        reservation: "Rezervimet e mia",
        register: "Regjistrohu",
        forgotPsw: "Keni harruar fjalëkalimin?",
        noAccount: "Nuk keni llogari?",
        doNotMatch: "Fjalëkalimet nuk përputhen!",
        acceptTerms: "Ju lutem pajtohuni me kushtet e privatësië!",
        passwordConfirmation: "Rishtyp fjalëkalimin",
        personalNo: "Numri identifikues",
        city: "Qyteti",
        country: "Shteti",
        optionsError: "Ju lutem zgjedhni njërin nga opsionet!",
        verifyMail: "Verifikoni Email Adresën",
        verifyMailToRegister:
            "Ju duhet të verifikoni email adresën për të kompletuar regjistrimin!",
        successVerification: "Verifikimi u krye me sukses!",
        errorVerification: "Ju kërkojmë falje, linku nuk është valid!",
        successVerificationMessage: "Llogaria juaj u aktivizua me sukses!",
        errorVerificationMessage:
            "Oops! Linku për verifikimin e llogarisë suaj nuk është valid!",
        mailExists: "Email Adresa tashmë ka llogari",
        phoneExists: "Numri kontaktues ka llogari",
        resendVerification: "Ridërgo linkun në email",
        contactSupport: "Kontaktoni njërin nga ekipa jonë!",
        sendLink: "Dërgo Linkun",
        writeMail: "Ju lutem shënoni email adresën",
        changePassword: "Ndrysho Fjalëkalimin",
        forgetPasswordError:
            "Kjo email adresë nuk ka llogari apo nuk është verifikuar akoma!",
        linkSuccess:
            "Linku u dërgua me sukses. Ju lutem kontrolloni email adresën!",
        verifyAccount:
            "Ju lutem kontrolloni email adresën për të aktivizuar llogarinë tuaj!",
        change: "Ruaj Ndryshimet",
        // GENERAL
        checkResults: "Shiko rezultatet",
        bookNow: "Rezervo",
        seePrices: "Shikoni çmimet tona",
        seePriceLink: "Këtu",
        gameRules: "Rregullat e lojës",
        gameSuggestion: "Sygjerime për lojëra të tjera",
        openModal: "Open",
        closeModal: "Close",
        watchVideo: "Shiko Videon",
        showMore: "Shiko me shumë",
        showLess: "Shiko me pakë",
        gender: "Gjinia",
        matchRequestError: 'Dicka shkoi keq! Provo sërish më vonë.',
        matchRequestSuccess: 'Rezervimi është bërë me sukses.',
        homepageKarting: `Garues bohuni gati për eksperienca plotë adrenalinë!

    Përjetoni vozitjen e nivelit profesional në pistën e brendshme 500m me Gokart- RSX2 krejt elektrik, i sigurtë dhe me performancë mbresëlënëse⚡️
    
    Aventura ju pret!`,
        otherLoginTerms: 'Duke u identifikuar me Google ose Facebook ju jeni dakord me Termat dhe Kushtet e HyperActive dhe politikën e privatësisë së kompanisë.',
        googleBtn: "Kyqu me Google",
        facebookBtn: "Kyqu me Facebook",
        //GAME TRANSLATE
        //KARTING
        kartingRules: [
            {
                num: "1",
                desc: "Të gjithë klientat duhet të nëshkruajnë dokumentin që pranon kushtet &amp; rreziqet e lojës dhe mban përgjegjësi mbi vete",
            },
            {
                num: "2",
                desc: "Personat nën moshën 12 vjeç nuk mund të garojnë vetë, por duhet të shoqërohen nga një person përgjegjës në GoKart-in me dy ulëse.",
            },
            {
                num: "3",
                desc: "Seanca e rezervuar do të skadojë nëse nuk arrini përpara kohës së fillimit të garës.",
            },
            {
                num: "4",
                desc: "Gjërat personale(Çantë,xhaketë dhe të tjera) duhet të vendosen në dollap tek recepsioni.",
            },
            { num: "5", desc: "Ndalohet përdorimi i telefonit gjatë vozitjes." },
            { num: "6", desc: "Ndalohet pirja e duhanit/cigareve elektronike." },
            { num: "7", desc: "Nuk lejohet ushqim apo pije në zonën e kartingut." },
            {
                num: "8",
                desc: "Veshja e balaklaves,helmetës dhe jelekut janë të detyruara deri në përfundim të garës.",
            },
            {
                num: "9",
                desc: "Mos filloni garën derisa të merrni konfirmim nga stafi ynë.",
            },
            {
                num: "10",
                desc: "Nga përplasjet e qëllimshme dhe të përsërituara,detyroheni të largoheni nga pista.",
            },
            {
                num: "11",
                desc: "Në rast të aksidentit,ngritni dorën dhe mos dilni nga GoKart.",
            },
            {
                num: "12",
                desc: "Kushtoni vëmendje udhëzimeve të marshallit (personi pëgjegjës i kompanisë) që mban flamurin.",
            },
            {
                num: "13",
                desc: "GoKart fillojnë të ngadalsohen automatikisht në fund të garës.",
            },
            {
                num: "14",
                desc: "Në pistë lejohen 12 GoKarts në të njëjtën kohë dhe sesioni i garës zgjat 8 minuta.",
            },
        ],

        //LASER TAG
        laserTagBannerHeader:
            "Çfarë është Laser Tag? Imagjinoni një video-lojë emocionuese live-aksion. Laser Tag Arena është e mbushur me pengesa që shtojnë intensitetin dhe e bëjnë çdoherë lojën të duket ndryshme.Është një lojë strategjie. Duhet të jesh i zgjuar, i shpejtë dhe i fokusuar nëse dëshiron të mbijetosh! Merrne shoqninë dhe sprovoni instinktet tuaja të mbijetesës ndërsa përpiqeni t'i mundni kundërshtarët tuaj.",
        // ARCADE
        arcadeBanner: `Kthehuni mbrapa n’kohë dhe rijetoni epokën e artë të arkadave! Koleksioni ynë i makinerive
    arcade është i mbushur plot nostalgji me lojërat klasike si dhe risitë më të fundit për lojtarë e të
    gjitha moshave. Nga titujt ekskluzivë të filmave të preferuar deri te lojërat e fëmirisë, ka diçka
    për të gjithë.
    Kshuqë mbledhe shoqninë e familjen tuaj dhe le t’fillojë aventura!`,
        arcadeBannerHeader: "",
        // VR
        VRBannerHeader: "",

        vrRules: [
            { num: "1", desc: "Personat nën moshën 12 vjeç nuk mund të luajnë." },
            {
                num: "2",
                desc: "Seanca e rezervuar do të skadojë nëse nuk arrini para kohës së fillimit të lojës.",
            },
            {
                num: "3",
                desc: "Klientët nuk duhet të luajnë kur ai ose ajo është i lodhur, ka nevojë për gjumë, është nën ndikimin e alkoolit ose drogës,ose vuan nga stresi emocional ose epilepsia.",
            },
            {
                num: "4",
                desc: "Nëse një klient përjeton ndonjë nga simptomat e mëposhtme: marramendje, shikim të paqartë, shtrëngime të syve ose muskujve, humbje të vetëdijes, çorientim gjatë lojës, ai ose ajo duhet të ndërpresë menjëherë lojën.",
            },
            {
                num: "5",
                desc: "Ndalohet përdorimi i telefonit nga personi që është në lojë.",
            },
            { num: "6", desc: "Ndalohet pirja e duhanit/cigareve elektronike." },
            { num: "7", desc: "Nuk lejohet ushqim apo pije në zonën e lojës." },
            {
                num: "8",
                desc: "Ndalohet prekja e pajisjeve pa udhëzimin e personit përgjegjës në Hologate Arena. ",
            },
        ],

        vrGames: [
            {
                gameName: "RevolVR 3",
                title: `Ages 12+ 
          Duck, squat and jump to show off your shooting skills in this competitive multiplayer VR cover-shooter`,
                desc: `Në një distopi të paligjshme të Perëndimit të Egër, revolveri është arma e vetme që u lejohet qytetarëve. Manjatët e armëve fillojnë të marrin me qira trupa robotësh për njerëzit me ambicie të nxehta në këmbim të testimit të revolverëve të tyre të sapokrijuar dhe pjesëmarrjes në përballje madhështore të ngjashme me kufirin.

          Tani je i ftuar t'i bashkohesh kësaj turme gjakpirëse.`,
            },
            {
                gameName: "Ragnaröck",
                title: `Ages 12+
          Beat the rhythm to win the Viking race!`,
                desc: `Hyni në botën mistike të vikingëve në këtë lojë konkurruese të mbushur me shumë lojtarë. Ragnaröck është i lehtë për t'u luajtur dhe i vështirë për t'u zotëruar. Si kapiten i një anijeje vikinge, gjithçka që ju nevojitet është ritmi për të lëvizur dhe për të udhëhequr ekuipazhin tuaj drejt fitores. Tregoni aftësitë tuaja, mundni rezultatin e lartë dhe mundni familjen dhe miqtë në këtë garë të stilizuar.`,
            },
            {
                gameName: "Ghostbusters VR Academy",
                title: `Become a Ghostbuster in new location-based Virtual Reality Experience launching in over 400 HOLOGATE locations globally.`,
                desc: `Për herë të parë ndonjëherë, lojtarët do të jenë në gjendje të stërviten për të qenë një Ghostbuster i vërtetë në një mjedis të mahnitshëm akademie dhe të garojnë me një version të ri fluturues të ECTO duke përdorur simulatorin e lëvizjes së automjetit të HOLOGATE. Kjo përvojë përmbushëse e fantazisë është e mundur vetëm në realitetin virtual dhe me teknologjinë argëtuese të bazuar në vendndodhje të HOLOGATE.`,
            },
            {
                gameName: "ANGRY BIRDS",
                title: `Ages 6+
          IT’S UP TO YOU AND THREE OF YOUR BIRD BUDDIES TO STOP THEM FROM TURNING YOUR IDYLLIC HOME INTO A MESSY, SMELLY… PIGSTY!`,
                desc: `The award-winning location-based VR platform, along with Sony Pictures Virtual Reality (SPVR) and Rovio Entertainment, brings you a brand new gaming experience. Up to four players can immerse themselves in a first-person VR world where the only thing standing between hordes of bad piggies and Bird Island is you… and your GIANT SLINGSHOT!`,
            },
            {
                gameName: "ZOMBYTE -2-0",
                title: `Ages 16+
          Take the fight back and infiltrate the Zombie stronghold in the subway tunnels.`,
                desc: `Virusi Zombyte ka ndryshuar dhe është përhapur në klasifikime edhe më të frikshme të zombive. ZOMBYTE 2 premton të ketë edhe më shumë kapje, kafshim dhe prerje. Merrni Z.W.A.T tuaj. ekipi, drejtohuni nën tokë dhe shikoni shpinën tuaj ndërsa lëvizni nga stacioni në stacion. Përdorni fuqizimet dhe mbulesën për të kaluar nëpër një grup të ri të zombive të tmerrshme më të paimagjinueshme.`,
            },
            {
                gameName: "SIGVRIED",
                title: `Ages 12+ 
          In an alternative future after the Ragnarök events, brave adventurers explore the destroyed, gigantic fortress of the gods, Vahalla.`,
                desc: `Në mënyrë që të zotërojnë thesaret e humbura të Nibelungs, lojtarët duhet të punojnë si një ekip për të kapërcyer enigmat dhe kurthet, për të luftuar përbindëshat dhe dragonjtë dhe për të shmangur sfidat shtesë përreth. Loja kombinon lojën klasike të lojës me role, një element enigmë të dhomës së arratisjes, të gjitha që ndodhin brenda një aventure fantastiko-shkencore shumë zhytëse dhe interaktive.`,
            },
            {
                gameName: "CYBERPUNK",
                title: `It’s the beginning of the 22nd century. Society has collapsed amid rapid technological progress.`,
                desc: `Njerëzit po modifikojnë gjerësisht trupin e tyre. Korporatat janë në luftë me njëra-tjetrën për të dhënat, të cilat janë bërë malli më i vlefshëm në treg. Grupi juaj, i përbërë nga kiborgë të pajisur me aftësi të veçanta, ka vendosur të vjedhë disa të dhëna të rralla nga arkivat e një korporate me ndikim. Duke u infiltruar në kompleks pa u vënë re, duhet të shkoni te arkivat dhe të shkarkoni kujtesën e rafte. Por nuk do të ketë asgjë të lehtë në këtë lojë.`,
            },
            {
                gameName: "The Prison",
                title: `Ages 12+ 
          Your private police force has been cracking down on criminal gangs for years and taking no prisoners.`,
                desc: `Ju jeni futur në burg për akuza të sajuara. Miqtë tuaj ende kanë lirinë e tyre. Plani i arratisjes është gati, dhe tani do t'ju duhet gjithë guximi, gjithë dinakëria dhe gjithë fati që keni për të arritur në liri dhe për të pastruar emrin tuaj. Dhe për të vazhduar luftën tuaj për drejtësi.`,
            },
            {
                gameName: "Blasters of the Universe",
                title: `Merrni miqtë tuaj dhe përpiquni të mbijetoni plumbave dhe të këqinjëve!`,
                desc: `Blasters of the Universe: VR Bullet Hell është një lojë bashkëpunuese me shumë lojtarë, e frymëzuar nga retro, që e shtyn zhanrin e ferrit të plumbave në lartësi të reja verbuese, duke ju vënë ju dhe miqtë tuaj përballë plumbave dhe njerëzve të këqij në një peizazh të realitetit virtual.`,
            },
            {
                gameName: "Mission Sigma",
                title: `Ages 12+
          A mund të kapërceni të gjitha kurthet dhe të parandaloni një sulm bërthamor?`,
                desc: `Këtu përfundon lajmi i mirë. Rezulton se në çatinë e një kati të lartë të braktisur, maniaku ka instaluar një kokë bërthamore me një kohëmatës mbi të. Vetë ndërtesa është kthyer në një kullë të mbushur me kurthe dhe pengesa të ndërlikuara. Ju jeni rekomanduar si specialist në enigma të tilla dhe shërbimi sekret ju ka ndihmuar të depërtoni në oborr. Pjesa tjetër varet nga ju`,
            },
            {
                gameName: "SLUGTERRA",
                title: `Ages 12+
          Bashkohuni dhe hyni në arenën konkurruese të universit SLUGTERRA!`,
                desc: `Zgjidhni nga Eli, 'Gjithpërhapësi', me kombinimin e tij të zjarrit sulmues dhe slugëve elektrike; Trixie, 'taktikani, slugat e të cilit ngadalësojnë kundërshtarët dhe depërtojnë përmes mbulesës; Pronto, 'Mashtrues', jetpack i të cilit e lejon të lëvizë shpejt, ndërsa ai pi duhan lojtarët me re pordhe ose i lyen me zhul acid; ose Kord, "Tanku", i cili lëviz më ngadalë, por mund të godasë kundërshtarët e tij me slugat e tij Rammstone dhe Sticky Grenuke.`,
            },
            {
                gameName: "Captain Cook",
                title: `Ages 6+
          Lojë gatimi familjare dhe bashkëpunuese.`,
                desc: `Captain Cook është një lojë gatimi argëtuese, miqësore për familjen dhe bashkëpunuese ku ju dhe miqtë tuaj do t'ju duhet të prisni, pjekni dhe shërbeni ushqimet e kërkuara nga radha e piratëve të uritur përpara se të mbarojë koha. Me piratët e tij të pazakontë dhe lojën e furishme, Captain Cook me siguri do të jetë një hit me lojtarët e të gjitha moshave dhe niveleve të aftësive.`,
            },
            {
                gameName: "Groove Guardians",
                title: `Ages 6+
          Elektrizuese, ritëm dhe kërcim, lojë beat-battle! `,
                desc: `GROOVE GUARDIANS të kombinuara me paraqitjen në ajër të hapur, ndriçimin interaktiv dhe ekranet e lojës së HOLOGATE krijojnë pistën e parë të vallëzimit me shumë lojtarë të realitetit virtual që hap koncepte të paprecedentë dhe jashtëzakonisht argëtuese për çdo vend argëtimi.`,
            },
            {
                gameName: "Zombyte",
                title: `Ages 16+
          Termocentrali - Rrahje zemre, post apokaliptike, sulm i zombive!`,
                desc: `Duke u mbështetur në historinë e gjatë të kompanisë në efektet vizuale kinematografike të nivelit të lartë për filmat artistikë, vendndodhjet reale u digjitalizuan duke përdorur teknologjinë më të fundit të skanimit me lazer dhe fotogrametrisë për të arritur një gjendje të pashembullt realizmi. Rezultati përfundimtar është një sulm i tmerrshëm, drithërues, i dorës së parë të apokalipsit të zombive, i cili e ka rritur faktorin e tij zvarritës nga mjedisi shumë i detajuar i lojërave të një magazine industriale të braktisur, të rrënuar.`,
            },
            {
                gameName: "Cold Clash",
                title: `Ages 6+
          Përjetoni luftën me topa bore si kurrë më parë.`,
                desc: `Manovroni hovercraft-in tuaj rreth liqenit të ngrirë për të mbledhur më shumë monedha ari se kundërshtarët tuaj, por nuk është aq e thjeshtë. Ju duhet të bëni rosë, bob dhe endje për të shpëtuar nga topat e dëborës fluturuese që vijnë nga lojtarët e tjerë dhe një Yeti i çmendur, territorial.`,
            },
            {
                gameName: "Towertag",
                title: `Ages 6+
          Fenomeni global i eSports.`,
                desc: `Në Tower Tag do të gjeni një nga përvojat më të pritura me shumë lojtarë në botë, dhe për ata prej jush që dëshironi një lojë të vërtetë esports për HOLOGATE-in tuaj, mos kërkoni më tej.

          Duke akomoduar deri në 8 lojtarë, në mënyra të shumta loje, Tower Tag është një ndeshje e përkryer për HOLOGATE ARENA.`,
            },
            {
                gameName: "Simurai",
                title: `Ages 12+
          Mbroni një bazë futuriste në planetin vendas të SIMURAI-t`,
                desc: `Teleportojeni veten në një terren më të lartë dhe "fuqini" për të fituar një strategji avantazh për të eliminuar kundërshtarin tuaj përpara se ata t'ju eliminojnë. Kjo lojë është krijuar për t'ju dhënë mundësinë për të organizuar turne për të angazhuar njësoj fillestarë, lojtarë të avancuar dhe spektatorë. Loja është fizikisht tërheqëse dhe e lehtë për t'u mësuar, por e vështirë për t'u zotëruar.`,
            },
            {
                gameName: "Simurai Arena",
                title: `Ages 12+
          Ti dhe ekipa  jote duhet të zotëroni kulla në arenë, duke parandaluar që kundërshtari juaj të fitojë shumë territor.`,
                desc: `Kjo lojë është krijuar për t'ju dhënë mundësinë për të organizuar turne për të angazhuar njësoj fillestarë, lojtarë të avancuar dhe spektatorë. Loja është fizikisht tërheqëse dhe e lehtë për t'u mësuar, por e vështirë për t'u zotëruar.`,
            },
            {
                gameName: "Das Boot",
                title: `Ages 6+ 
          Një përvojë emocionuese, bashkëpunuese, nëndetëse, ekskluzivisht për HOLOGATE`,
                desc: `Në DAS BOOT VR ESCAPE katër lojtarë punojnë në bashkëpunim për të marrë kontrollin e një u-varke gjermane në një mision vdekjeprurës të frymëzuar nga filmi anti-luftë i vlerësuar nga kritikët dhe seritë televizive Sky. Në misionin për të kapur anije mallrash aleate në Atlantik, ju dhe një nëndetëse miqësore jeni në pritë nga shkatërruesit britanikë.`,
            },
            {
                gameName: "Rig Rebels",
                title: `Ages 12+ 
          Bastisni një strukturë futuriste në këtë PvP me gjuajtje të shpejta plot aksion.`,
                desc: `Ku lojtarët mund të përdorin duart e tyre për të lëvizur rreth mjedisit shumë të detajuar, steampunk. E shkëlqyeshme për lojtarët me përvojë ose klientët që kërkojnë një sfidë konkurruese, Rig Rebels kërkon që lojtarët të ndajnë fokusin e tyre midis gjuajtjes, mbledhjes së burimeve, mburojave dhe fuqizimeve, si dhe lëvizjes dhe pozicionit.`,
            },
            {
                gameName: "Hyper GP",
                title: `Ages 6+ 
          Pilotoni automjete futuristike të fluturimit në gara me ritme të shpejta dhe me shumë lojtarë!`,
                desc: `Njerëzimi tani ka teknologjinë për të krijuar koloni në Hënë, dhe energjia e lirë nuk është më një ëndërr… Por ka krijuar gjithashtu mjete fluturuese që shkojnë vërtet, me të vërtetë, shpejt! Pilotoni automjete futuriste rreth ishujve lundrues, nëpër toka të shkretëtirës dhe nëpër shpella gjigante akulli në një garë me shumë lojtarë me ritëm të shpejtë deri në vijën e finishit! Duke prezantuar lojën e parë për HOLOGATE BLITZ, kjo është HYPER GP!`,
            },
        ],
        // AXE THROWING
        axeThrowingBannerHeader: "",

        axeRules: [
            {
                num: "1",
                type: "Mosha e Lejuar",
                desc: "Të gjithë klientat duhet të nëshkruajnë dokumentin që pranon kushtet & rreziqet e lojës dhe mban përgjegjësi mbi vete.",
            },
            { num: "2", desc: "Personat nën moshën 18 vjeç nuk mund të luajnë." },
            {
                num: "3",
                desc: "Seanca e rezervuar do të skadojë nëse nuk arrini përpara kohës së fillimit të garës.",
            },
            {
                num: "4",
                desc: "Klientët nuk duhet të luajnë kur ai ose ajo është i lodhur, ka nevojë për gjumë, është nën ndikimin e alkoolit ose drogës,ose vuan nga stresi emocional ose epilepsia.",
            },
            {
                num: "5",
                desc: "Nëse një klient përjeton ndonjë nga simptomat e mëposhtme: marramendje, shikim të paqartë, shtrëngime të syve ose muskujve, humbje të vetëdijes, çorientim gjatë lojës, ai ose ajo duhet të ndërpresë menjëherë lojën.",
            },
            {
                num: "6",
                desc: "Këpucët e mbyllura janë të detyrueshme për secilin person që hyn në zonën e hedhjes.",
            },
            {
                num: "7",
                desc: "Veshjet si kapelat, takat, shalli dhe aksesorët mund të krijojnë probleme duke kufizuar lëvizjen gjatë lojës.",
            },
            {
                num: "8",
                desc: "Asnjëherë mos hyni në zonën e hedhjes derisa personi pëgjegjës të japë leje.",
            },
            {
                num: "9",
                desc: "Asnjëherë mos e kaloni vijën e hedhjes derisa sëpatat të jenë në objektiv ose në dysheme.",
            },
            {
                num: "10",
                desc: "Ndalohen ushqimi dhe pijet brenda zonës së hedhjes.",
            },
            { num: "11", desc: "Ndalohet pirja e duhanit/cigareve elektronike." },
            {
                num: "12",
                desc: "Ndalohet përdorimi i telefonit brenda zonës së hedhjes.",
            },
        ],
        // ESCAPE ROOM
        escapeRoomBannerHeader: `Escape Rooms janë dhoma të mbushura plot adrenalinë që i hedhin pjesëmarrësit drejtpërdrejt në aksion me një mision për të zgjidhur enigmat dhe sfidat. Ju dhe ekipi juaj transportoheni në një botë të re ku duhet të garoni kundër orës ku duhet zgjedhur enigmat sfiduese për të gjetur daljen.

      Shumë emocione e adrenalinë ju presin brenda dhomave, një përvojë zhytëse që do ju sfidoj mendjen dhe do testoj aftësitë tuaja për zgjidhjen e mistereve në dhomat:
      
      La Mision Del Profesor, Haunted Mansion, One Thousand & One Nights
      School of Magic
      `,

        // ESCAPE GAMES
        escapeGames: [
            {
                gameName: "LA MISIÓN DEL PROFESOR",
                title: ``,
                desc: `Bëhu anëtar e bandës më të famshme dhe provoni me thy bankën. Ikni sigurimit  dhe printoni sa më shumë para që mundeni.

          Koha për grabitjen më të madhe të të gjitha kohërave ka ardh, kshtuqë vendosni maskat e uniformat dhe tregoni aftësitë tuaja.
          Plani është krejt gati dhe paratë ju presin, por kur dyert e Mint të mbyllen, ekipi juaj do të testohet si kurrë më parë.
  
          A mundeni me i bo ballë presionit dhe zgjedhjeve të vështira, apo do dështoni e do ktheheni kundër njoni-tjetrit?
          Mos harroni, plani i profesorit osht krejt gati dhe përgjegjsia dhe rreziku më i madh jeni ju.`,
            },
            {
                gameName: "SCHOOL OF MAGIC",
                title: ``,
                desc: `Një aventurë arratisjeje me veçori dhe lojëra jashtëzakonisht të avancuara të teknologjisë, shumë faktorë dhe efekte vizuale, që krijojnë një përvojë unike të jetës reale.
        
          Fuqia e zëzë osht zgju. Vetëm fuqia e Perlës së Magjepsur të Skarletit, e fshehur brenda një dhome të fshehtë plot me magji të rrezikshme mund t'i japë fund mbretërimit të tij të territoreve. Ju dhe ekipi juaj i magjistarëve dhe shtrigave duhet të merrni gurin dhe të mposhtni errësirën në rritje, ose gjithçka do të humbasë. Gutuni, sepse koha nuk është në anën tuaj.`,
            },
            {
                gameName: "ONE THOUSAND & ONE NIGHTS",
                title: ``,
                desc: `Bëhuni pjesë e një eksperience kinematografike, duke eksploruar sekretet e një mbretërie të Lindjes.

          Ju, banorë të guximshëm të Abbasidit, tash është koha për të treguar besnikërinë tuaj dhe për të shpëtuar kalifatin tonë nga Khalifi i Errët dhe plani i tij i mbrapshtë. Ai tashmë arrin të pushtojë Kalifatët fqinjë dhe të mërgojë Sulltanin tonë. Hapi i tij i ardhshëm është të shkatërrojë tokën dhe popullin tonë, të dominojë zonën dhe të krijojë një Kalifat të ri që do të sundohet nën pushtetin e frikës. Ju jeni i vetmi që mund t'i rezistoni dhe mundeni dhe të shpëtoni tokën tonë nga kjo fatkeqësi e ardhshme.`,
            },
            {
                gameName: "HAUNTED MANSION",
                title: ``,
                desc: `Një dhomë inovative horror me atmosferë intensive dhe unike.
          Kombinimi i efekteve vizuale, zanore dhe kërcitëse, ju ofron një përvojë më të mirë të lojës.
          
          Ju jeni "hetuesit më me përvojë të të mbinatyrshmes". Ekipi juaj u afrua nga një burrë misterioz, i cili ju ofroi një shumë të madhe parash për të hyrë brenda një rezidence specifike dhe për të kërkuar një objekt të fuqishëm të lashtë. Ai me mjeshtëri shmangu t'ju informonte për të gjithë hetuesit e mëparshëm që kishte punësuar, që ata nuk u kthyen më kurrë…
          
          Por ai nuk ju tregoi një gjë…`,
            },
        ],

        //GAME RULES
        escapeRules: [
            { num: "1", desc: "Personat nën moshën 10 vjeç nuk mund të luajnë." },
            {
                num: "2",
                desc: "Seanca e rezervuar do të skadojë nëse nuk arrini para kohës së fillimit të lojës.",
            },
            {
                num: "3",
                desc: "Gjerat personale(Çantë,xhaketë dhe të tjera) duhet të vendosen në dollap tek recepsioni.",
            },
            { num: "4", desc: "Ndalohet përdorimi i telefonit." },
            { num: "5", desc: "Ndalohet pirja e duhanit/cigareve elektronike." },
            { num: "6", desc: "Nuk lejohet ushqim apo pije brenda dhomës." },
            {
                num: "7",
                desc: "Nuk lejohet të kërceni apo të vraponi brenda hapësirave.",
            },
            {
                num: "8",
                desc: "Ndalohet prekja e dekoreve dhe gjërave të cilat kanë mbishkrimin “DO NOT TOUCH’’.",
            },
            {
                num: "9",
                desc: "Gjërat që ju përdorni gjatë lojës,sigurohuni që pa dashje të mos i merrni me Vete.",
            },
        ],

        // ESCAPE GAME HAINTED HOUSE
        escapeRules2: [
            {
                num: "1",
                type: "Mosha e Lejuar",
                desc: `Mosha minimale për të luajtur është 15+.`,
            },
            {
                num: "2",
                type: "Kush nuk duhet të luaj.",
                desc: `Personat shtatzënë, personat që kanë klaustrofobi dhe personat që vuajnë nga ndonjë sëmundje e zemrës,epilepsi, apo ndonjë sëmundje tjetër nuk lejohet të luajnë.`,
            },
            {
                num: "3",
                type: "Rezervimi",
                desc: `Seanca e rezervuar do të skadojë nëse nuk arrini përpara kohës së fillimit të lojës.`,
            },
            {
                num: "4",
                type: "Gjërat personale",
                desc: `Gjerat personale(Çantë,xhaketë dhe të tjera) duhet të vendosen në dollap tek recepsioni.`,
            },
            {
                num: "5",
                type: "Paisjet elektronike dhe ushqimi",
                desc: `Mosha minimale për të luajtur është 15+.`,
            },
            {
                num: "6",
                type: "Brenda Dhomave",
                desc: `Nuk lejohet të kërceni apo të vraponi brenda hapësirave.`,
            },
            {
                num: "7",
                type: "Paisjet e Escape Rooms",
                desc: `Ndalohet prekja e dekoreve dhe gjërave të cilat kanë mbishkrimin “DO NOT TOUCH’’, në rast dëmtimi gjatë lojës, klientet duhet të paguajnë dëmin e shkaktuar. Gjërat që ju përdorni gjatë lojës,sigurohuni që pa dashje të mos i merrni me vete.`,
            },
        ],

        // BOWLING
        bowlingBannerHeader: "",
        // LODRA
        lodraBannerHeader: "",
    },
};

export default lng;