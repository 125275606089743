import { useSelector } from "react-redux";
import ActivitiesBanner from "../../../components/activities/ActivitiesBanner";
import TextWrapper from "../../../components/general/text/TextWrapper";
import style from "./Activities.module.css";
import ActivitiesInterest from "../../../components/activities/ActivitiesInterest";
// import SingleCarousel from "../../../components/carousel/SingleCarousel";
import ImageTextCard from "../../../components/card/ImageTextCard";
// import CustomCard from "../../../components/activities/CustomCard";
// import RadiantBanner from "../../../components/general/banner/RadiantBanner";
// import SpeedScales from "../../../components/activities/SpeedScales";
// import VideoWrapper from "../../../components/general/videos/VideoWrapper";
import Grid from "../../../layout/grid/Grid";
// import BigCards from "../../../components/card/BigCard";
import constants from "../../../utils/consts";
import { useEffect, useRef } from "react";
// import FullBanner from "../../../components/general/banner/FullBanner";
import Box from "@mui/material/Box";
// import { Typography } from "@mui/material";
import RandomCards from "../../../components/card/RandomCards";
import ReserveText from "../../../components/reservation/ReserveText";
import { useState } from "react";
// import { bowling } from "../../../utils/gameLng";
import lng from "../../../utils/language";
// import { AnimatePresence, motion } from "framer-motion";
import FetchData from "../../../api/FetchData";
import GridBox from "../../../layout/grid/GridBox";
import GalleryBox from "../../../components/gallery/Gallery";
import PartyBanner from "../../../components/general/banner/PartyBanner";
import { bowling, laser } from "../../../utils/gameLng";
import Typography from '@mui/material/Typography'
import Videos from "../../../components/general/videos/Videos";
import WaitingLoader from "../../../components/general/loader/WaitingLoader";
import Text from "../../../components/text/Text";

function Bowling() {
    const { language } = useSelector((state) => state.language);

    //   const gamePreparation = useRef(null);
    const games = useRef(null);
    const gallery = useRef(null);
    const bookParty = useRef(null);


    const [gameData, setGameData] = useState();
    const [loading, setLoading] = useState(true)

    const load = () => {
        FetchData.create('/data/getAll', {
            id: 7,
            lng: language === "alb" ? 1 : 2
        })
            .then(res => {
                setGameData(res.data.data)
                setLoading(false);
            })
            .catch(err => {
                // console.log('Error', err);
            })
    }

    useEffect(() => {
        load();
        // eslint-disable-next-line
    }, [language])

    //   const reverse = (state, setstate) => {
    //     setstate(!state);
    //   }

    const interest = {
        eng: {
            interest: [
                {
                    interest: 'Types of games',
                    ref: games
                },
                {
                    interest: 'Galery',
                    ref: gallery
                },
                {
                    interest: 'Celebrate your birthday',
                    ref: bookParty
                },
            ]
        },
        alb: {
            interest: [
                {
                    interest: 'Llojet e lojërave',
                    ref: games
                },
                {
                    interest: 'Galeria',
                    ref: gallery
                },
                {
                    interest: 'Festo ditëlindjen',
                    ref: bookParty
                },
            ]
        }
    }

    const containerSpacing = {
        paddingY: '80px'
    }

    return (
        <div className={`${style.detailsWrapper}`}>
            {
                loading === false ?
                    <>

                        <div className="container">
                            {
                                gameData[1] &&
                                <ActivitiesBanner
                                    title={gameData[1].body_header}
                                    desc={gameData[1].body_desc}
                                    image={constants.imgPath + gameData[1].images}
                                    linkPrice="karting"
                                    vidId="EOH1621WY_w"
                                    id={gameData[1].link}
                                    changeColor='#FAA000'
                                    prices={true}
                                />
                            }
                            <ActivitiesInterest
                                interests={interest[language].interest}
                            //   title={bowling[language]["clickInterest"]}
                            />

                            <Box
                                className={'bodyWrapper'}
                                sx={{ height: { md: '500px', sm: '416px', xs: '209px' }, position: 'relative' }}
                            >
                                <Box sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0, width: 1, height: 1, backgroundColor: 'transparent' }} />
                                <Videos id={'EOH1621WY_w'} />
                            </Box>

                            <Typography marginTop={5} variant="h6" sx={[{ whiteSpace: "pre-line", textAlign: 'center', fontSize: { md: '20px', xs: '15px' }, paddingTop: '50px', paddingBottom: '200px' }]}>
                                {bowling[language]['filler']}
                            </Typography>

                            <Box sx={{ marginTop: 5 }} ref={games}>
                                {

                                    gameData[8] &&
                                    <>
                                        <Text
                                            containerStyling={{
                                                marginY: 10
                                            }}
                                            header={bowling[language]['gameType']}
                                            desc={bowling[language]['gameTypeDesc']}
                                        />
                                        <Grid no={"ReverseSingle"}>
                                            {gameData[8].map((i, j) => {
                                                return (
                                                    <ImageTextCard
                                                        data={i}
                                                        key={j}
                                                        reservationType={true}
                                                        costumButton={{
                                                            marginTop: '15px',
                                                            width: 'fit-content',
                                                            trainsition: '.3s all',
                                                            border: 'none !important',
                                                            backgroundColor: '#AF46FA',
                                                            padding: '10px 30px !important',
                                                            borderRadius: '30px !important',
                                                            '&:hover': {
                                                                color: '#AF46FA',
                                                                backgroundColor: '#FFF',
                                                            }
                                                        }}
                                                        txtContainerStyling={{
                                                            marginY: 'auto',
                                                            display: 'grid',
                                                            alignItems: 'center',
                                                            justifyContent: 'unset',
                                                            gridTemplateRows: '1fr 1fr',
                                                        }}

                                                    />
                                                )
                                            })}
                                        </Grid>
                                    </>
                                }
                            </Box>

                            {gameData[7] && (
                                <Box className={["container"]} sx={{ marginTop: 15 }} ref={gallery}>
                                    <TextWrapper title={gameData[7].wrapper_header} />
                                    <GridBox
                                        gap={2}
                                        gridTemplateColumns={{
                                            xs: "repeat(2, 1fr)",
                                            sm: "repeat(3, 1fr)",
                                            md: "repeat(4, 1fr)",
                                        }}
                                        gridTemplateRows={{
                                            xs: "repeat(3, 1fr)",
                                            sm: "repeat(3, 1fr)",
                                            md: "repeat(2, 1fr)",
                                        }}
                                    >
                                        <GalleryBox galleryImages={gameData[7].images} />
                                    </GridBox>
                                </Box>
                            )}

                            <div className={style.wrapper}>
                                {
                                    gameData[9] &&
                                    <Grid no={"ReverseSingle"}>
                                        {gameData[9].map((i, j) => {
                                            return <ImageTextCard data={i} key={j} specialStyling={{
                                                backgroundColor: 'transparent'
                                            }} />;
                                        })}
                                    </Grid>
                                }
                            </div>
                        </div>
                        <Box className={style.wrapper} ref={bookParty} sx={containerSpacing}>
                            <PartyBanner
                                header={lng[language]['hyperBirthdayHeader']}
                                desc={lng[language]['hyperBirthdayDesc']}
                                buttonTxt={lng[language]['partNow']}
                                link={"/activities/party"}
                                imageUrl={"https://hyper-active.com/images/escaperoom/banner/birthdayParty.png"}
                                containerStyle={{
                                    width: { sm: "454px", xs: '90%' },
                                    color: "#FFF",
                                    background: "linear-gradient(180deg, rgba(240,60,70,1) 0%, rgba(250,160,0,1) 100%)",
                                    padding: 3,
                                    borderRadius: "40px",
                                    display: "grid",
                                    gridTemplateRows: "1fr 70px 60px",
                                    rowGap: "20px",
                                }}
                            />
                        </Box>


                        <div className="container" sx={containerSpacing}>
                            <ReserveText header={lng[language]['rezNow']}
                                desc={laser[language]['reservetXT']}
                                id={739728}
                            />
                            <Box paddingBottom={5} sx={containerSpacing}>
                                <Box sx={{'h1': {textAlign: 'center'}}}>
                                    <TextWrapper title={lng[language]['gameSuggestion']} />
                                </Box>
                                <RandomCards removeGame='Bowling' />
                            </Box>
                        </div>
                    </>
                    :
                    <WaitingLoader open={loading} />
            }
        </div>
    );
}
export default Bowling;
