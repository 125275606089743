import style from "./Reservation.module.css";
import Grid from "../../../layout/grid/Grid";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../../components/general/loader/Loader";
// import Wrapper from "../../../components/general/Wrapper";
import { api } from "../../../api/config";
import TextWrapper from "../../../components/general/text/TextWrapper";
import lng from "../../../utils/language";
import { 
    // useDispatch, 
    useSelector } from "react-redux";
// import { applyCouponDispatcher } from "../../../redux/app/cart/actions";

function ReservationActivities() {
//   const dispatch = useDispatch();
  
  const [reservation, setReservation] = useState([]);
  const [loading, setLoading] = useState(true);
  const { language } = useSelector((state) => state.language);
  
//   const {appliedCoupon} = useSelector((state) => state.cart);
  
  useEffect(() => {
    api.get("reservation/activities")
    .then((res) => {
    //Check message type
      if (res.data) {
        setReservation(res?.data);
        setLoading(false);
      }
    // if(!appliedCoupon)  dispatch(applyCouponDispatcher("test"));
    })
    .catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <>
      {!loading ? (
        <div className={`container ${style.atractions}`}>
          <TextWrapper title ={lng[language]["bookNow"]} desc="" />
          <Grid no={"Activities"}>
            {reservation.map((data, index) => (
              <Link
                style={{
                  backgroundImage: `url(../assets/reservation/${data.img})`,

                }}
                to={`/${data.route}`}
                className={`${style.atractionsLink}`}
                key={data.externalId}
                state={{
                  name: data.nameAlb,
                  id: data.externalId,
                }}
              >
                {
                    data.OnlyLogo === 0 &&
                    data.nameAlb
                }
              </Link>
            ))}
          </Grid>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
}
export default ReservationActivities;
