import style from "./Header.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Logo from "../../components/general/Logo";
import Grid from "../../layout/grid/Grid";
import { Box, Typography } from "@mui/material";
import { BiUser } from "react-icons/bi";
import { FiShoppingCart } from "react-icons/fi";
import { logOutUser } from "../../app/auth/Auth";
import { useState } from "react";
import { setLanguage } from "../../app/language/Language";
import lng from "../../utils/language";
// import ReactPixel from "../../utils/pixel/reactPixel";
import { FaPhone } from "react-icons/fa6";
// import { removeAll } from "../../app/cart/Cart";

function Header(props) {
    const dispatch = useDispatch();
    const { language } = useSelector((state) => state.language);

    const [menuOpen, setMenuOpen] = useState(false);
    const { authenticated, user } = useSelector((state) => state?.auth);
    const { items } = useSelector((state) => state.cart);
    const logout = () => {
        dispatch(logOutUser());
        // dispatch(removeAll())
    };

    const changeLang = (val) => {
        dispatch(setLanguage(val));
    };

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth',
        });
    };

    return (
        <header>
            <div className={style.header}>
                <div className="container">
                    <Grid no={"Header"}>
                        <Logo />
                        <div className={style.headerList}>
                            {/* {!authenticated &&
              <Typography component={Link} sx={{marginRight: 2, fontSize: '14px', '&:hover': {background: '#321eff'}}} to='/register' className={style.resrverBtn}>
              Regjistrohu
                </Typography>
            } */}

                            <Typography component={Link} sx={{
                                marginRight: '10px',
                                fontSize: '14px',
                                textDecoration: 'none',
                                color: '#FFF',
                                padding: '10px 15px',
                                borderRadius: '40px',
                                transition: '.2s all',
                                background: "linear-gradient(180deg, rgba(70,4,193,1) 0%, rgba(174,68,250,1) 70%)",
                                '&:hover': {
                                    background: '#FFF',
                                    color: "rgba(174,68,250,1)",
                                }
                            }} to='/activities/bussiness'>
                                HyperBusiness
                            </Typography>

                            <Typography component={Link} sx={{
                                marginRight: '10px',
                                fontSize: '14px',
                                textDecoration: 'none',
                                color: '#FFF',
                                padding: '10px 15px',
                                borderRadius: '40px',
                                transition: '.2s all',
                                background: "linear-gradient(180deg, rgba(240,60,70,1) 0%, rgba(250,160,0,1) 70%)",
                                '&:hover': {
                                    background: '#FFF',
                                    color: "rgba(250,160,0,1)",
                                }
                            }} to='/activities/party'>
                                Birthday Party
                            </Typography>


                            <Typography component={Link} sx={{ fontSize: '14px', padding: '10px 15px', '&:hover': { background: '#321eff' } }} to="/reservation" className={style.resrverBtn}>
                                {lng[language]['rezNow']}
                            </Typography>
                            <Typography 
                                className={style.loginInfo} 
                                onClick={() => scrollToBottom()} 
                                sx={{ 
                                    marginLeft: '10px' ,
                                    backgroundColor: 'transparent',
                                    border: '1px solid var(--white)',
                                    borderRadius: 'var(--radius)',
                                    color: 'white',
                                    textDecoration: 'none',
                                    fontSize: '14px',
                                    cursor: 'pointer',
                                    width: '35px',
                                    height: '35px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    transition: '0.5s',
                                }}
                            >

                                    <FaPhone />

                            </Typography>
                            <Box component={Link} to="/cart" className={style.cardContainer} sx={{ marginRight: '5px' }}>
                                <FiShoppingCart size={20} />
                                {items.length > 0 && <span>{items.length}</span>}
                            </Box>



                            <div className={style.login}>


                                {
                                    authenticated == false ?
                                        <Link to="/login" className={style.loginInfo}>
                                            <div>
                                                {authenticated ? (
                                                    <p>
                                                        {user?.name.charAt(0)}
                                                        {user?.lastname.charAt(0)}
                                                    </p>
                                                ) : (
                                                    <BiUser size={20} />
                                                )}
                                            </div>
                                        </Link>
                                        :
                                        <Box className={style.loginInfo} onClick={() => setMenuOpen(!menuOpen)}>
                                            <div>
                                                {authenticated ? (
                                                    <p>
                                                        {user?.name.charAt(0)}
                                                        {user?.lastname.charAt(0)}
                                                    </p>
                                                ) : (
                                                    <BiUser size={20} />
                                                )}
                                            </div>
                                        </Box>
                                }

                                {authenticated && (
                                    <Box className={style.loginMenu} sx={menuOpen ? { display: 'flex' } : { display: 'none' }}>
                                        <ul>
                                            <li>
                                                <Link to="/user/reservation">{lng[language]["reservation"]}</Link>
                                            </li>
                                            <li>
                                                <Link to="/user/reservation">{lng[language]["reservation"]}</Link>
                                            </li>
                                            <li>
                                                <Link to="/user/profile">{lng[language]["profile"]}</Link>
                                            </li>
                                            <li onClick={() => logout()}>{lng[language]["logout"]}</li>
                                        </ul>
                                    </Box>
                                )}

                            </div>
                            <Box component={'ul'} sx={{ padding: '0 0 0 10px' }}>
                                <Box
                                    component={'li'}
                                    sx={{
                                        padding: '0 !important',
                                        opacity: language === 'alb' && '0.7',
                                        cursor: 'pointer'
                                    }}
                                    className={`${language === 'eng' && style.active}`} onClick={() => changeLang('eng')}>ENG</Box>
                                <Box
                                    component={'li'}
                                    sx={{
                                        padding: '0 !important',
                                    }}
                                >/</Box>
                                <Box
                                    component={'li'}
                                    sx={{
                                        padding: '0 !important',
                                        opacity: language === 'eng' && '0.7',
                                        cursor: 'pointer'
                                    }}
                                    className={`${language === 'alb' && style.active}`} onClick={() => changeLang('alb')}>AL</Box>
                            </Box>
                        </div>
                    </Grid>
                </div>
            </div>
            <div className={style.blueHeader}>
                <div className="container">
                    <ul>
                        {props.menu &&
                            props.menu.map((data, index) => (
                                <li key={index}>
                                    <Link to={data.url}>{data.title}</Link>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </header>
    );
}

export default Header;
