import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
// import { useForm, SubmitHandler } from "react-hook-form"
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FetchData from '../../api/FetchData';
import { Alert } from '@mui/material';

const inputLabel = {color: '#000', marginBottom: 0.4}

const initialState = {
    name: '',
    lastname: '',
    email: '',
    mob: '',
    city: '',
    country: '',
    dob: '',
    gender: ""
}

const initialStateError = {
    nameError: false,
        lastnameError: false,
        emailError: false,
        mobError: false,
        cityError: false,
        countryError: false,
        dobError: false,
        genderError: false 
}

export default function RegisterShow() {

    const [error, setError] = React.useState({
        hasError: false,
        msg: '',
        severity: 'info'
    });

    const [userData, setUserData] = React.useState(initialState)
    const [userDataerror, setuserDataError] = React.useState(initialStateError)

    const changeState = {
        nameChange: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                setuserDataError({ ...userDataerror, nameError: true })
            } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value === "") {
                setuserDataError({ ...userDataerror, nameError: false })
            }
            setUserData({ ...userData, name: value })
        },
        lastNameChange: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                setuserDataError({ ...userDataerror, lastnameError: true })
            } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value === "") {
                setuserDataError({ ...userDataerror, lastnameError: false })
            }
            setUserData({ ...userData, lastname: value })
        },
        emailChange: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/)) {
                setuserDataError({ ...userDataerror, emailError: true })
            } else if (value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/) || value === "") {
                setuserDataError({ ...userDataerror, emailError: false })
            }
            setUserData({ ...userData, email: value })
        },
        mobChange: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/)) {
                setuserDataError({ ...userDataerror, mobError: true })
            } else if (value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/) || value === "") {
                setuserDataError({ ...userDataerror, mobError: false })
            }
            setUserData({ ...userData, mob: value })
        },
        cityChange: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                setuserDataError({ ...userDataerror, cityError: true })
            } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value === "") {
                setuserDataError({ ...userDataerror, cityError: false })
            }
            setUserData({ ...userData, city: value })
        },
        countryChange: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                setuserDataError({ ...userDataerror, countryError: true })
            } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value === "") {
                setuserDataError({ ...userDataerror, countryError: false })
            }
            setUserData({ ...userData, country: value })
        },
        dobChange: (e) => {
            var value = e.target.value;
            if (value !== "" && !value.match(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/)) {
                setuserDataError({ ...userDataerror, dobError: true })
            } else if (value.match(/^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/) || value === "") {
                setuserDataError({ ...userDataerror, dobError: false })
            }
            setUserData({ ...userData, dob: value })
        },
        genderChange: (e) => {
            var value = e.target.value;
            if (value !== "" && value === undefined) {
                setuserDataError({ ...userDataerror, genderError: true })
            } else if (value === "") {
                setuserDataError({ ...userDataerror, genderError: false })
            }
            setUserData({ ...userData, gender: value })
        },
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const validation = Object.values(userDataerror);
        const confirmation = validation.includes(true);
        if (confirmation === false) {
            FetchData.create('register/register', userData)
            .then(res => {
                if (res.data.hasError === false) {
                    setUserData(initialState);
                    setuserDataError(initialStateError);
                    setError({
                        hasError: true,
                        msg: res.data.msg,
                        severity: 'success'
                    })
                }else{
                    setError({
                        hasError: true,
                        msg: res.data.msg,
                        severity: 'error'
                    })
                }
            })
            .catch((err) => {
                console.log(err);
            })
        }else{
            setError({
                hasError: true,
                msg: 'Sigurohuni që të gjitha fushat janë të mbushura/Make sure all the forms are filled',
                severity: 'error'
            })
        }

    }

    return (
        <Box sx={{
            width: '100%',
            background: '#FFF',
            height: '100vh',
            backgroundImage: 'url("https://hyper-active.com/assets/images/Portal.jpg")',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>

            <Box component={'form'} sx={{width: '80%', height: '80%', background: '#FFF', borderRadius: '20px', padding: '20px'}}onSubmit={(e) => handleSubmit(e)}>
                <Box sx={{width: '80%', marginX: 'auto'}}>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Box 
                        component={'img'}
                        src='https://hyper-active.com/images/HyperActive.svg'
                        sx={{
                            height: '200px',
                            marginX: 'auto'
                        }}
                    /> 
                </Box>

                <Box 
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 1fr)',
                        gridGap: '20px'
                    }}
                >
                        
                    <Box>
                        <InputLabel sx={inputLabel}>Emrin/Name</InputLabel>
                        <TextField
                            value={userData.name}
                            required
                            fullWidth
                            placeholder='Hyper'
                            error={userDataerror.nameError}
                            onChange={(e) => changeState.nameChange(e)}
                        />
                    </Box>

                    <Box>
                        <InputLabel sx={inputLabel}>Mbiemri/Last Name</InputLabel>
                        <TextField
                            value={userData.lastname}
                            required
                            fullWidth
                            placeholder='Active'
                            error={userDataerror.lastnameError}
                            onChange={(e) => changeState.lastNameChange(e)}
                        />
                    </Box>

                    <Box>
                        <InputLabel sx={inputLabel}>Email</InputLabel>
                        <TextField
                            value={userData.email}
                            required
                            fullWidth
                            placeholder='info@hyper-active.com'
                            error={userDataerror.emailError}
                            onChange={(e) => changeState.emailChange(e)}
                        />
                    </Box>

                    <Box>
                        <InputLabel sx={inputLabel}>Numri kontaktues/Phone Number</InputLabel>
                        <TextField
                            value={userData.mob}
                            required                        
                            fullWidth
                            placeholder='+383XXXXXX'
                            error={userDataerror.mobError}
                            onChange={(e) => changeState.mobChange(e)}
                        />
                    </Box>

                    <Box>
                        <InputLabel sx={inputLabel}>Qyteti /City</InputLabel>
                        <TextField
                            value={userData.city}
                            required
                            fullWidth
                            placeholder='Prishtinë'
                            error={userDataerror.cityError}
                            onChange={(e) => changeState.cityChange(e)}
                        />
                    </Box>
                    <Box>
                        <InputLabel sx={inputLabel}>Shteti/Country</InputLabel>
                        <TextField
                            value={userData.country}
                            required
                            fullWidth
                            placeholder='Kosovë'
                            error={userDataerror.countryError}
                            onChange={(e) => changeState.countryChange(e)}
                        />
                    </Box>

                    <Box>
                        <InputLabel sx={inputLabel}>Data e lindjes/Date of Birth</InputLabel>
                        <TextField
                            value={userData.dob}
                            required
                            fullWidth
                            placeholder='01/01/2000'
                            error={userDataerror.dobError}
                            onChange={(e) => changeState.dobChange(e)}
                        />
                    </Box>

                    <Box>
                        <InputLabel sx={inputLabel}>Gjinia/Gender</InputLabel>
                        <FormControl fullWidth required>
                            <Select
                                error={userDataerror.genderError}
                                onChange={(e) => changeState.genderChange(e)}
                                value={userData.gender ? userData.gender : ""}
                                required
                            >
                            <MenuItem value={'m'}>M</MenuItem>
                            <MenuItem value={'f'}>F</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    
                </Box>
                <Button 
                    variant='contained'
                    type='submit'
                    sx={{
                        width: '100%',
                        paddingY: '12px', 
                        marginTop: '25px',
                        backgroundColor: '#000',
                        ':hover': {
                            color: '#000',
                            backgroundColor: '#FFF'
                        }
                    }}
                >
                    Regjistrohu
                </Button>
                </Box>
            </Box>
            {
                error.hasError &&
                <Alert 
                    severity={error.severity} 
                    variant="filled" 
                    sx={{position: 'absolute', bottom: '30px', left: '30px'}}
                    onClose={() => {setError({
                        hasError: false,
                        msg: '',
                        severity: 'info'
                    })}}
                >
                    {error.msg}
                </Alert>
            }
        </Box>
    )
}