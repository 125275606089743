import { useSelector } from "react-redux";
import ActivitiesBanner from "../../../components/activities/ActivitiesBanner";
import TextWrapper from "../../../components/general/text/TextWrapper";
import style from "./Activities.module.css";
import lng from "../../../utils/language";
import ActivitiesInterest from "../../../components/activities/ActivitiesInterest";
// import RadiantBanner from "../../../components/general/banner/RadiantBanner";
// import Grid from "../../../layout/grid/Grid";
// import Gallery from "../../../components/carousel/Gallery";
import constants from "../../../utils/consts";
import AppBanner from "../../../components/general/banner/AppBanner";
import ReserveText from "../../../components/reservation/ReserveText";
import GeneralLayout from "../../../layout/activities/GeneralLayout";
import React, { useRef } from "react";
import GridBox from "../../../layout/grid/GridBox";
import GalleryBox from "../../../components/gallery/Gallery";
import Box from "@mui/material/Box";
// import { Typography } from "@mui/material";
import RandomCards from "../../../components/card/RandomCards";
// import { useState } from "react";
// import { AnimatePresence, motion } from "framer-motion";
import FetchData from "../../../api/FetchData";
import WaitingLoader from "../../../components/general/loader/WaitingLoader";

function AxeThrowing() {
    const { language } = useSelector((state) => state.language);

    //   const games = useRef(null);
    //   const gamePreparation = useRef(null);
    const rules = useRef(null);
    const gallery = useRef(null);

    //   const [show1, setShow1] = useState(false);
    //   const [show2, setShow2] = useState(false);
    //   const [show3, setShow3] = useState(false);
    //   const [show4, setShow4] = useState(false);

    const [gameData, setGameData] = React.useState();
    const [loading, setLoading] = React.useState(true);

    const load = () => {
        FetchData.create('/data/getAll', {
            id: 5,
            lng: language === "alb" ? 1 : 2
        })
            .then(res => {
                setGameData(res.data.data);
                setLoading(false);
            })
            .catch(err => {
                // console.log('Error', err);
            })
    }

    React.useEffect(() => {
        load();
        // eslint-disable-next-line
    }, [language])

    //   const reverse = (state, setstate) => {
    //     setstate(!state);
    //   }

    const interest = {
        eng: {
            interest: [
                // {
                //   interest: 'Game Preperation',
                //   ref: gamePreparation
                // },
                {
                    interest: 'Rules',
                    ref: rules
                },
                {
                    interest: 'Gallery',
                    ref: gallery
                },
            ]
        },
        alb: {
            interest: [
                // {
                //   interest: 'Përgaditjet',
                //   ref: gamePreparation
                // },
                {
                    interest: 'Rregullat',
                    ref: rules
                },
                {
                    interest: 'Galeria',
                    ref: gallery
                },
            ]
        }
    }

    //   const preparation = {
    //     eng: {
    //       preparation: [
    //         {
    //           title: "Friends",
    //           desc: `Invite friends, family or colleagues to join you in the shoot. 

    //           Sack shooting can be played alone or with a maximum of 4 people. 

    //           All clients must sign the document accepting the terms & risks of the game and bear responsibility.`,
    //           diplay: () => reverse(show1, setShow1),
    //           state: show1
    //         },
    //         {
    //           title: "Booking",
    //           desc: `To secure your session with the team, we advise you to pre-book online. 

    //           You have the flexibility to reserve the game for 30 minutes or more.`,
    //           diplay: () => reverse(show2, setShow2),
    //           state: show2
    //         },
    //         {
    //           title: "Arrival",
    //           desc: `When you arrive, meet with your team in the game room for setup at least 10 minutes before your session. 

    //           During the preparation period, you will receive instructions on the rules of the game and familiarize yourself with the equipment you will use during the game.`,
    //           diplay: () => reverse(show3, setShow3),
    //           state: show3
    //         },
    //         {
    //           title: "Age",
    //           desc: "The sachets are available for adults only. The minimum age of shooters is limited to 18 years.",
    //           diplay: () => reverse(show4, setShow4),
    //           state: show4
    //         },
    //       ],
    //     },
    //     alb: {
    //       preparation: [
    //         {
    //           title: "Shoqëria",
    //           desc: `Ftoni miqtë, familjen ose kolegët t'ju bashkohen në gjuajtje. 

    //           Gjuajtja me sakicë mund të luhet vetëm apo me një maksimum prej 4 personash. 

    //           Të gjithë klientat duhet të nëshkruajnë dokumentin që pranon kushtet & rreziqet e lojës dhe mban përgjegjësi mbi vete.`,
    //           diplay: () => reverse(show1, setShow1),
    //           state: show1
    //         },
    //         {
    //           title: "Rezervimi",
    //           desc: `Për të siguruar seancën tuaj me ekipin, ju këshillojmë të rezervoni paraprakisht online. 

    //           Ju keni fleksibilitetin për të rezervuar lojën për 30 minuta e më shumë.`,
    //           diplay: () => reverse(show2, setShow2),
    //           state: show2
    //         },
    //         {
    //           title: "Ardhja",
    //           desc: `Kur të mbërrini, mblidhuni me ekipin tuaj në dhomën e lojës për përgaditje të paktën 10 minuta para seancës tuaj. 

    //           Gjatë periudhës së përgatitjes, do të merrni udhëzime mbi rregullat e lojës dhe do të njiheni me pajisjet që do të përdorni gjatë lojës.`,
    //           diplay: () => reverse(show3, setShow3),
    //           state: show3
    //         },
    //         {
    //           title: "Mosha",
    //           desc: "Sakicat janë të disponueshme vetëm për të rritur. Mosha minimale e gjuajtësve është e kufizuar në 18 vjeç.",
    //           diplay: () => reverse(show4, setShow4),
    //           state: show4
    //         },
    //       ]
    //     }
    //   }

    //   const bigCard = [
    //     {
    //       imgSrc: constants.imgPath + "Mask Group 127.png",
    //       gameName: "Agjentët",
    //       title: "4-24 Lojtarë • 10 min Mosha: 6+",
    //       desc: "Të gjithë lojtarët fillojnë në një ekip blu dhe shpërndahen në arenë. Menjëherë pas fillimit të lojës, disa lojtarë rastësisht marrin ngjyrë të kuqe. Këta marrin 50 pikë për çdo 5 sekonda që qëndrojnë të kuq. Lojtarët e kuq mund të etiketojnë blutë për t'u mbrojtur. Megjithatë, nëse një lojtar blu shënon një të kuqe, ai 'vjedh' ngjyrën e kuqe. Më pas lojtari i kuq ndryshon në një blu dhe pagesa automatike e pikëve ndalet, ndërsa lojtari blu ndryshon ngjyrën në të kuqe dhe tani i merr vetë pikët.",
    //     },
    //     {
    //       imgSrc: constants.imgPath + "Mask Group 159.png",
    //       gameName: "Gladiatorët",
    //       title: "4-24 Lojtarë • 10 min Mosha: 6+",
    //       desc: "Në lojën 'Gladiatorët' të gjithë lojtarët fillojnë me ngjyrën e gjelbër. Lojtarët ndryshojnë ngjyrën ndërsa fitoni pikë duke etiketuar lojtarët/shënjestrat. Nëse lojtarët nivelohen, nga njëra anë ju mund të shënoni më shpejt ose merrni një përmirësim të mburojës, nga ana tjetër ju jepni më shumë pikë kundërshtarëve nëse goditeni! Lojtarët humbasin gjysmën e pikëve të një shënuesi (pas nivelit 1), duke u kërkuar atyre të performojnë më të mirën e tyre për të shmangur uljen në nivel.",
    //     },
    //     {
    //       imgSrc: constants.imgPath + "Mask Group 180.png",
    //       gameName: "One Shot",
    //       title: "4-24 Lojtarë • 10 min Mosha: 6+",
    //       desc: "One Shot Free For All është një kombinim i Delta One Shot dhe Delta Free For All. Me një mburojë 30, është të gjithë kundër të gjithëve. Kini kujdes - një shenjë e saktë dhe ju jeni goditur! Komponentët e Arenës përmbushin cilësimet e tyre One Shot.",
    //     },
    //     {
    //       imgSrc: constants.imgPath + "Mask Group 179.png",
    //       gameName: "Thikë mas shpine",
    //       title: "4-24 Lojtarë • 10 min Mosha: 6+",
    //       desc: "Në këtë modalitet loje, lojtarët duhet të përpiqen të kalojnë fshehurazi pas kundërshtarëve për të shënuar sensorin e pasmë. Më së shumti pikë jepen për shënimin e sensorit të pasmë - mbajeni shpinën të lirë! Ka 60 pikë për shënjimin e sensorëve të shpatullave, 50 pikë për sensorin e përparmë dhe 200 pikë për sensorin e pasmë.",
    //     },
    //     {
    //       imgSrc: constants.imgPath + "Mask Group 182.png",

    //       gameName: "Delta Tag",
    //       title: "4-24 Lojtarë • 10 min Mosha: 6+",
    //       desc: "Kjo është një lojë standarde me dy mënyra fazar dhe një mburojë të plotë. Lojtarët janë të ndarë në dy (ose më shumë) ekipe dhe duhet të shënojnë kundërshtarët në arenë. Komponentët e arenës përmbushin funksionet e tyre standarde.",
    //     },
    //     {
    //       imgSrc: constants.imgPath + "Mask Group 181.png",

    //       gameName: "Free For All",
    //       title: "4-24 Lojtarë • 10 min Mosha: 6+",
    //       desc: "Hunter Energy Free For All është një formë e llojit të lojës Hunter. Duke përdorur një modalitet të ngadalshëm, por shumë të fuqishëm (një etiketë një goditje) fazar, lojtarët kanë për detyrë të gjejnë dhe etiketojnë të gjithë lojtarët e tjerë përpara se të etiketohen vetë. Nuk ka ekipe në këtë mënyrë loje, të gjithë janë për vete. Është e rëndësishme në lojë të shënoni kundërshtarin fillimisht, pasi shënimi tjetër kërkon 5 sekonda për t'u ngarkuar - koha për të sulmuar kundërshtarin.",
    //     },
    //   ];

    //   const img = [
    //     {

    //       imgPath: constants.axeThrowingGallery+'Wndr_Hyper (5 of 77).jpg',
    //       imgAlt: ''
    //     },
    //     {

    //       imgPath: constants.axeThrowingGallery+'Wndr_Hyper (6 of 77).jpg',
    //       imgAlt: ''
    //     },
    //     {

    //       imgPath: constants.axeThrowingGallery+'Wndr_Hyper (7 of 77).jpg',
    //       imgAlt: ''
    //     },
    //     {
    //       imgPath: constants.axeThrowingGallery+'Wndr_Hyper (8 of 77).jpg',
    //       imgAlt: ''
    //     },
    //     {
    //       imgPath: constants.axeThrowingGallery+'Wndr_Hyper (9 of 77).jpg',
    //       imgAlt: ''
    //     },
    //     {
    //       imgPath: constants.axeThrowingGallery+'Wndr_Hyper (10 of 77).jpg',
    //       imgAlt: ''
    //     }
    //   ];

    return (
        loading === false ?
            <div className={`${style.detailsWrapper} ${style.organeWrapper}`}>
                <div className="container">
                    {
                        gameData[1] &&
                        <ActivitiesBanner
                            title={gameData[1].body_header}
                            desc={gameData[1].body_desc}
                            image={constants.imgPath + gameData[1].images}
                            linkPrice="karting"
                            vidId="ZmkoDcjWrM8"
                            id={gameData[1].link}
                            prices={true}
                            changeColor='#FAA000'
                        />
                    }
                    <ActivitiesInterest
                        interests={interest[language].interest}
                    // title={axe[language]["clickInterest"]}
                    />
                    {/* <div className="bodyWrapper" ref={gamePreparation}>
          <TextWrapper title={lng[language]['prepretaionGame']} />
          <Grid no={4}>
            {preparation[language].preparation.map((i, j) => {
              return (
                <Box className={style.prep} key={j} onClick={i.diplay}>
                <AnimatePresence>
                  <Box 
                    component={motion.div}
                    whileHover={{
                      scale: i.state === true ? 1 : 1.2,
                    }}
                    sx={{
                      margin: 'auto',
                      transition: '.3s all',
                      width: 'fit-content',
                      borderRadius: '20px',
                      background: i.state === true ? '#FFF !important' : 'transparent',
                      scale: i.state === true ? '1.2 !important' : '1',
                    }}
                  > 
                  <Typography component={'h2'}
                  sx={{
                    textAlign: 'center',
                    width: 'max-content',
                    borderRadius: '20px',
                    transition: '.3s all',
                    padding: '5px 15px',
                    color: '#FAA000 !important',
                    cursor: 'pointer',
                    marginBottom: '10px',
                  }} 
                  fontWeight={'bold'}>{i.title}</Typography>
                  </Box>
                </AnimatePresence>
                <Typography component={"p"} sx={{ whiteSpace: "pre-line" , display: i.state === true ? 'block' : 'none'}}>
                  {i.desc}
                </Typography>
              </Box>
              );
            })}
          </Grid>
        </div> */}
                </div>
                {
                    gameData[7] &&
                    <Box className="container" sx={{ marginTop: 10 }} ref={gallery}>
                        <TextWrapper
                            title={gameData[7].wrapper_header}
                        />
                        <GridBox
                            gap={2}
                            gridTemplateColumns={
                                {
                                    xs: 'repeat(2, 1fr)',
                                    sm: 'repeat(3, 1fr)',
                                    md: 'repeat(4, 1fr)'
                                }
                            }
                            gridTemplateRows={{
                                xs: 'repeat(3, 1fr)',
                                sm: 'repeat(3, 1fr)',
                                md: 'repeat(2, 1fr)'
                            }}
                        >
                            <GalleryBox galleryImages={gameData[7].images} />
                        </GridBox>
                    </Box>
                }
                <div className="container">

                    {/* {
            gameData[2] &&
          <div className="bodyWrapper" ref={rules}>
              <RadiantBanner
              header={gameData[2].body_header}
              text={gameData[2].body_desc}
              urlTxt={lng[language]["gameRules"]}
              imgUrl={constants.imgPath + gameData[2].images}
              />
          </div>
        } */}
                    {
                        gameData[9] &&
                        <div className="bodyWrapper">
                            <GeneralLayout data={gameData[9]} />
                        </div>
                    }
                </div>
                <div className="container">
                    {
                        gameData[3] &&
                        <div className="bodyWrapper">
                            <AppBanner
                                header={lng[language]['hyperAppHeader']}
                                desc={lng[language]['hyperAppDesc']}
                                imgUrl={'../images/6582cf3c6d165.webp'}
                            />
                        </div>
                    }
                    <ReserveText header={lng[language]['rezNow']} id={739732} />
                    <Box paddingBottom={5}>
                            <Box sx={{'h1': {textAlign: 'center'}}}>
                                <TextWrapper title={lng[language]['gameSuggestion']} />
                            </Box>
                        <RandomCards removeGame='Axe Throwing' />
                    </Box>
                </div>
            </div>
            :
            <WaitingLoader open={loading} />
    );
}
export default AxeThrowing;
