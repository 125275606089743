import React from 'react';
import Box from '@mui/material/Box';
import Text from '../../../components/text/Text';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { birthdayRoom, partyRoom } from '../../../utils/roomsLng';
import { useSelector } from "react-redux";
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import DatePicker from "react-datepicker";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FetchData from '../../../api/FetchData';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Recapcha from '../../../components/recaptcha/Recapcha';

export default function BirthdayRoom2() {
    const { language } = useSelector((state) => state.language);
    const ref = React.useRef(null);
    const [partyRequest, setPartyRequest] = React.useState({
        fullName: '',
        email: '',
        phone: '',
        date: new Date(),
        guests: '',
        requestsAndDetails: '',
        partType: ''
    });

    const [partyRequestError, setPartyRequestError] = React.useState({
        fullNameError: false,
        emailError: false,
        phoneError: false,
        dateError: false,
        guestsError: false,
        requestsAndDetailsError: false
    });

    const [recatpchaVal, setRecaptchaVal] = React.useState(null);
    const [success, setSuccess] = React.useState(false);
    const [msg, setMsg] = React.useState({
        type: ''
    })

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSuccess(false);
    };

    const defaultContainerSpacing = {
        paddingY: '80px'
    }

    // const defaultContainerSpacingBottom = {
    //     paddingBottom: '80px'
    // }

    // const defaultContainerSpacingTop = {
    //     paddingTop: '80px'
    // }

    const mainContainer = {
        width: {
            lg: '1024px',
            md: '',
            sm: '',
            xs: '90%',
        },
        marginX: 'auto'
    }

    const inputs = [
        {
            typeOf: 'Normal',
            size: 'double', label: partyRoom[language]['fullName'], type: 'text', multiline: false, required: true, id: 'name', value: partyRequest.fullName,
            error: partyRequestError.fullNameError,
            multiCall: (e) => {
                var value = e.target.value;
                if (value !== "" && !value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g)) {
                    setPartyRequestError({ ...partyRequestError, fullNameError: true })
                } else if (value.match(/^[A-Za-z]( ?[A-Za-z] ?)*$/g) || value === "") {
                    setPartyRequestError({ ...partyRequestError, fullNameError: false })
                }
                setPartyRequest({ ...partyRequest, fullName: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px',
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                }
            },
        },
        {
            typeOf: 'Normal',
            size: 'double', label: partyRoom[language]['email'], type: 'email', multiline: false, required: true, id: 'email', value: partyRequest.email,
            error: partyRequestError.emailError,
            multiCall: (e) => {
                var value = e.target.value;
                if (value !== "" && !value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/)) {
                    setPartyRequestError({ ...partyRequestError, emailError: true })
                } else if (value.match(/^[\w\-.]+@([\w-]+\.)+[\w-]{2,}/) || value === "") {
                    setPartyRequestError({ ...partyRequestError, emailError: false })
                }
                setPartyRequest({ ...partyRequest, email: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px',
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                }
            },
        },
        {
            typeOf: 'Normal',
            size: 'double', label: partyRoom[language]['phone'], type: 'tel', multiline: false, required: true, id: 'phone', value: partyRequest.phone,
            error: partyRequestError.phoneError,
            multiCall: (e) => {
                var value = e.target.value;
                if (value !== "" && !value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/)) {
                    setPartyRequestError({ ...partyRequestError, phoneError: true })
                } else if (value.match(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/) || value === "") {
                    setPartyRequestError({ ...partyRequestError, phoneError: false })
                }
                setPartyRequest({ ...partyRequest, phone: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px',
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                }
            },
        },
        {
            typeOf: 'Date',
            size: 'double', label: partyRoom[language]['date'], type: 'date', multiline: false, required: true, id: 'date', value: partyRequest.date,
            error: partyRequestError.dateError,
            multiCall: (e) => {
                var value = e;
                var time = new Date() - 1;
                if (value === time || value !== "") {
                    setPartyRequestError({ ...partyRequestError, dateError: true })
                } else if (value !== time || value === "") {
                    setPartyRequestError({ ...partyRequestError, dateError: false })
                }
                setPartyRequest({ ...partyRequest, date: value })
            },
            styling: {
                '.react-datepicker-wrapper input': {
                    width: '91.5%',
                    paddingY: '12.8px',
                    paddingX: '14px',
                    height: '1.4375em',
                    borderRadius: '20px',
                    backgroundColor: '#E3E3E3',
                    // borderColor: data.error ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)',
                    borderColor: 'rgba(0, 0, 0, 0.23)',
                    outline: '0'
                },
                '.react-datepicker-wrapper input:focus': {
                    borderColor: '#1975d2',
                    borderWidth: '2px'
                },
            },
        },

        // SINGLE INPUTS
        {
            typeOf: 'Normal',
            size: 'double', label: partyRoom[language]['guests'], type: 'number', multiline: false, required: true, id: 'guests', value: partyRequest.guests,
            error: partyRequestError.guestsError,
            multiCall: (e) => {
                var value = e.target.value;
                if (value !== "" && !value.match(/^\d+$/)) {
                    setPartyRequestError({ ...partyRequestError, guestsError: true })
                } else if (value.match(/^\d+$/) || value === "") {
                    setPartyRequestError({ ...partyRequestError, guestsError: false })
                }
                setPartyRequest({ ...partyRequest, guests: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px',
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                }
            },
        },

        {
            typeOf: 'Select',
            size: 'double', label: partyRoom[language]['partyType'], required: true, id: 'partyType', value: partyRequest.partType,
            error: null,
            multiCall: (e) => {
                var value = e.target.value;
                setPartyRequest({ ...partyRequest, partType: value })
            },
            styling: {
                '.MuiSelect-select ': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px !important',
                },
                'MuiSelect-select:focus': {
                    borderRadius: '20px',
                },
                '.MuiInputBase-input': {
                    paddingY: 1.6
                },
                '.MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                }
            },
        },

        {
            typeOf: 'Single',
            size: 'single', label: partyRoom[language]['request'], type: 'text', multiline: true, required: true, id: 'guests', value: partyRequest.requestsAndDetails,
            error: partyRequestError.requestsAndDetailsError,
            multiCall: (e) => {
                var value = e.target.value;
                setPartyRequest({ ...partyRequest, requestsAndDetails: value })
            },
            styling: {
                '.MuiInputBase-root': {
                    backgroundColor: '#E3E3E3',
                    borderRadius: '20px',
                },
                '.MuiInputBase-input': {
                    // paddingY: 1.6
                }
            },
        }
    ]

    const handleSubmit = (e) => {
        e.preventDefault();
        const validation = Object.values(partyRequestError);
        const confirmation = validation.includes(true);
        if (confirmation === false) {
            FetchData.create('/data/partyEvents', partyRequest)
            .then((res => {
                setPartyRequest({
                    fullName: '',
                    email: '',
                    phone: '',
                    date: new Date(),
                    guests: '',
                    requestsAndDetails: '',
                    partType: ''
                })
                setSuccess(true)
                setMsg({...msg, type: res.data.type})
            }))
            .catch((err) => {
                setSuccess(true)
                setMsg({...msg, type: "error"})
            })
        }else{
            setSuccess(true)
            setMsg({...msg, type: "error"})
        }
    }

    const captchaChange = (val) => {
        setRecaptchaVal(val)
    }

    const handleClick = () => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const packages = [
        {
            imgUrl: 'https://hyper-active.com/images/rooms/party rooms/compressed/package-bdPage-1.png',
            title: birthdayRoom[language]['packagesTitle1'],
            helperTxt: birthdayRoom[language]['packageshelperTxt'],
            // PRICE GRP    
            price: '11',
            minPrice: '99',
            forWho: birthdayRoom[language]['packageForWho'],
            features: [
                {
                    title: birthdayRoom[language]['room'],
                },
                {
                    title: birthdayRoom[language]['water'],
                },
                {
                    title: birthdayRoom[language]['slushy'],
                },
                {
                    title: birthdayRoom[language]['pizza'],
                },
                {
                    title: birthdayRoom[language]['snacks'],
                },
                {
                    title: birthdayRoom[language]['loje'],
                },
                {
                    title: birthdayRoom[language]['photo'],
                },
                {
                    title: birthdayRoom[language]['karaoke'],
                },
            ],
            value: 1,
            change: (e) => {
                handleClick();
                var value = e.target.value;
                setPartyRequest({ ...partyRequest, partType: value })
            }
        },
        {
            imgUrl: 'https://hyper-active.com/images/rooms/party rooms/compressed/package-bdPage-2.png',
            title: birthdayRoom[language]['packagesTitle2'],
            helperTxt: birthdayRoom[language]['packageshelperTxt'],
            // PRICE GRP    
            price: '14',
            minPrice: '99',
            forWho: birthdayRoom[language]['packageForWho'],
            features: [
                {
                    title: birthdayRoom[language]['room'],
                },
                {
                    title: birthdayRoom[language]['water'],
                },
                {
                    title: birthdayRoom[language]['tea'],
                },
                {
                    title: birthdayRoom[language]['pizza'],
                },
                {
                    title: birthdayRoom[language]['snacks'],
                },
                {
                    title: birthdayRoom[language]['loje'],
                },
                {
                    title: birthdayRoom[language]['bowling'],
                },
                {
                    title: birthdayRoom[language]['arcade'],
                },
                {
                    title: birthdayRoom[language]['photo'],
                },
                {
                    title: birthdayRoom[language]['karaoke'],
                },
            ],
            value: 2,
            change: (e) => {
                handleClick();
                var value = e.target.value;
                setPartyRequest({ ...partyRequest, partType: value })
            }
        },
        {
            imgUrl: 'https://hyper-active.com/images/rooms/party rooms/compressed/package-bdPage-3.png',
            title: birthdayRoom[language]['packagesTitle2'],
            helperTxt: birthdayRoom[language]['packageshelperTxt'],
            // PRICE GRP    
            price: '17',
            minPrice: '99',
            forWho: birthdayRoom[language]['packageForWho'],
            features: [
                {
                    title: birthdayRoom[language]['room'],
                },
                {
                    title: birthdayRoom[language]['water'],
                },
                {
                    title: birthdayRoom[language]['tea'],
                },
                {
                    title: birthdayRoom[language]['pizza'],
                },
                {
                    title: birthdayRoom[language]['snacks'],
                },
                {
                    title: birthdayRoom[language]['loje'],
                },
                {
                    title: birthdayRoom[language]['bowling'],
                },
                {
                    title: birthdayRoom[language]['laser'],
                },
                {
                    title: birthdayRoom[language]['arcade'],
                },
                {
                    title: birthdayRoom[language]['photo'],
                },
                {
                    title: birthdayRoom[language]['karaoke'],
                },
            ],
            value: 3,
            change: (e) => {
                handleClick();
                var value = e.target.value;
                setPartyRequest({ ...partyRequest, partType: value })
            }
        },
    ]

    return (
        <Box sx={[{ backgroundColor: '#4600BE', color: '#FFF' }, defaultContainerSpacing]}>

            <Box sx={mainContainer}>

                {/* <Box sx={defaultContainerSpacingBottom}>
                    <Text
                        header={birthdayRoom[language]['mainHeader']}
                        desc={birthdayRoom[language]['mainDesc']}
                        headerStyle={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            fontSize: {
                                sm: '35px',
                                xs: '28px'
                            },
                            marginBottom: '10px'
                        }}
                        descStyling={{
                            fontWeight: 'bold',
                            fontSize: {
                                sm: '37px',
                                xs: '24px'
                            },
                            textAlign: 'center'
                        }}
                    />
                    <Box sx={{ height: '700px', width: '100%', marginTop: '50px' }}>
                        <Box component={'img'} sx={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '30px' }} src='https://hyper-active.com/images/rooms/party rooms/compressed/main-bdPage.png' />
                    </Box>
                </Box> */}

                <Box sx={defaultContainerSpacing}>
                    <Text
                        header={birthdayRoom[language]['packageHeader']}
                        headerStyle={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            fontSize: '35px',
                            marginBottom: '10px'
                        }}
                        containerStyling={{
                            paddingBottom: '80px'
                        }}
                    />

                    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', rowGap: '100px' }}>
                        {
                            packages.map((data, index) => (
                                <Box key={index} sx={{ display: 'grid', padding: { sm: 'inherit', xs: '20px' }, gridTemplateColumns: { sm: '0.9fr 1.1fr', xs: '1fr' }, position: 'relative', height: { sm: '600px', xs: '100%' }, columnGap: '20px' }}>
                                    <Box sx={{
                                        maxHeight: '100%',
                                        position: { sm: 'relative', xs: 'absolute' },
                                        height: '100%',
                                        top: { sm: 'unset', xs: 0 },
                                        left: { sm: 'unset', xs: 0 },
                                        opacity: { sm: 1, xs: 0.5 }
                                    }}>
                                        <Box component={'img'} src={data.imgUrl} sx={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '20px' }} />
                                    </Box>
                                    <Box sx={{ display: 'grid', gridTemplateRows: '0fr 0fr 0fr 0fr', rowGap: '20px', marginLeft: '30px', zIndex: 2 }}>

                                        <Typography variant="h3" sx={{ fontSize: '38px', fontWeight: 'bold', width: {sm:'290px', xs: '100%'} }}>
                                            {data.title}
                                        </Typography>

                                        <Typography variant="body2" sx={{ fontSize: '22px', fontWeight: 'bold' }}>
                                            {data.helperTxt}
                                        </Typography>

                                        <Box sx={{ position: 'relative', width: 'max-content', display: 'flex' }}>
                                            <Box sx={{ display: 'flex' }}>
                                                <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: '50px', marginTop: '10px' }}>
                                                    €
                                                </Typography>

                                                <Typography variant="h3" sx={{ fontSize: '90px', fontWeight: 'bold', marginLeft: '10px' }}>
                                                    {data.price}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', marginTop: '12px' }}>
                                                <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: '36px' }}>
                                                    {data.minPrice}
                                                </Typography>

                                                <Typography variant="h3" sx={{ lineHeight: '16px', fontWeight: 'bold', fontSize: '19px', width: '76px' }}>
                                                    {data.forWho}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box component={'ul'} sx={{ listStyle: 'none', margin: '0', padding: '0' }}>
                                            {
                                                data.features.map((features, featuresIndex) => (
                                                    <Box key={featuresIndex} component={'li'} sx={{ marginY: '5px' }}>
                                                        <Typography variant="body2" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                                            -{features.title}
                                                        </Typography>
                                                    </Box>
                                                ))
                                            }
                                        </Box>

                                        <Box height={'100%'} sx={{ height: '100%', display: 'flex', alignItems: 'end' }}>
                                            <Button value={data.value} onClick={e => data.change(e)} variant='contained' sx={{ background: '#F03C44', padding: '15px 50px', borderRadius: '30px', boxShadow: 'none', '&:hover': { backgroundColor: '#FFF', color: '#F03C44' } }}>
                                                {partyRoom[language]['rezNow']}
                                            </Button>
                                        </Box>

                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>

                </Box>

                {/* Reservation Form */}
                <Box sx={defaultContainerSpacing} ref={ref}>
                    <Box
                        sx={{ borderRadius: '40px', border: 'solid 3px #AF46FA' }}>
                        <Text
                            header={partyRoom[language]['formHeader']}
                            containerStyling={{
                                textAlign: 'center', position: 'relative', marginX: 'auto',
                                '&::before': { borderTopLeftRadius: '30px', borderTopRightRadius: '30px', width: 1, height: 1, content: "''", position: 'absolute', backgroundColor: '#AF46FA' }
                            }}
                            headerStyle={{ fontWeight: 700, zIndex: 1, marginBottom: '25px', marginTop: '20px', fontSize: { sm: '44px', xs: '30px' } }}
                        />

                        <Box component={'form'} sx={[{ width: { sm: '70%', xs: '90%' }, marginX: 'auto' }, defaultContainerSpacing]} onSubmit={(e) => handleSubmit(e)}>
                            <Box sx={{ rowGap: '20px', display: 'grid', columnGap: '20px', gridTemplateColumns: { sm: '1fr 1fr', xs: '1fr' } }}>
                                {inputs.map((data, index) => (data.size === 'double' && data.typeOf === 'Normal' ?
                                    <Box key={index}>
                                        <InputLabel shrink id={data.id} sx={{ color: '#FFF', fontSize: '20px' }}>{data.label}</InputLabel>
                                        <TextField error={data.error} required={data.required} fullWidth id={data.id} sx={data.styling} onChange={(e) => data.multiCall(e)} type={data.type} defaultValue={data.value} />
                                    </Box>
                                    : data.typeOf === 'Date' ?
                                        <Box key={index}>
                                            <InputLabel shrink id={data.id} sx={{ color: '#FFF', fontSize: '20px' }}>{data.label}</InputLabel>
                                            <Box sx={data.styling}>
                                                <Box component={DatePicker} selected={data.value} onChange={(date) => data.multiCall(date)} minDate={new Date()} />
                                            </Box>
                                        </Box> : data.typeOf === "Select" ?
                                            <Box key={index}>
                                                <InputLabel shrink id={data.id} sx={{ color: '#FFF', fontSize: '20px' }}>{data.label}</InputLabel>
                                                <FormControl fullWidth required={data.required}>
                                                    <Select
                                                        labelId={data.id}
                                                        id={data.id}
                                                        value={data.value}
                                                        label="Age"
                                                        onChange={(e) => data.multiCall(e)}
                                                        sx={data.styling}
                                                    >
                                                        <MenuItem value={1}>{partyRoom[language]['firstPackage']}</MenuItem>
                                                        <MenuItem value={2}>{partyRoom[language]['secondPackage']}</MenuItem>
                                                        <MenuItem value={3}>{partyRoom[language]['thirdPackage']}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                            :
                                            ''
                                ))}
                            </Box>

                            <Box sx={{ rowGap: '20px', display: 'grid', columnGap: '20px', gridTemplateColumns: '1fr', marginTop: '30px' }}>
                                {inputs.map((data, index) => (data.size === 'single' && data.typeOf === 'Single' &&
                                    <Box key={index}>
                                        <InputLabel shrink id={data.id} sx={{ color: '#FFF', fontSize: '20px' }}>{data.label}</InputLabel>
                                        <TextField multiline={data.multiline} error={data.error} required={data.required} fullWidth id={data.id} minRows={'6'} sx={data.styling} onChange={(e) => data.multiCall(e)} type={data.type} defaultValue={data.value} />
                                    </Box>
                                ))}
                            </Box>
                            <Box marginTop={4}>
                                <Recapcha change={captchaChange} state={recatpchaVal} />
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                                <Button type='submit' variant='contained' disabled={!recatpchaVal} sx={{ backgroundColor: '#321EFF', boxShadow: 'none', paddingY: 2, paddingX: 5, color: '#FFF', borderRadius: '40px', '&.Mui-disabled': {border: 'solid 1px red'}, '&:hover': { backgroundColor: '#FFF', '.MuiTypography-body1': { color: '#321EFF' } } }}>
                                    <Typography variant="body1" color="#FFF" fontWeight={'500'}>{partyRoom[language]['submitBnt']}</Typography>
                                </Button>
                            </Box>
                        </Box>

                    </Box>
                </Box>

                {/* Call Now Component */}
                <Box sx={defaultContainerSpacing}>
                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                        <Text
                            header={partyRoom[language]['rezNow']}
                            desc={partyRoom[language]['contactTeam']}
                            containerStyling={{ textAlign: 'center', marginBottom: '20px' }}
                            headerStyle={{ fontWeight: 700, marginBottom: '20px' }}
                            descStyling={{ marginX: 'auto', width: language === "alb" ? '280px' : '361px !important' }}
                        />
                        <Button variant='contained' sx={{ backgroundColor: '#F03C46', color: '#FFF', borderRadius: '40px', paddingX: 3, paddingY: 1.5, '&:hover': { backgroundColor: '#FFF', 'h6': { color: '#F03C46' } } }} href="tel:+38349909196">
                            <Typography variant="h6" color="#FFF">{partyRoom[language]['callNow']}</Typography>
                        </Button>
                    </Box>
                </Box>
            </Box>
            {
                success &&
                <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
                    <Alert
                        onClose={handleClose}
                        severity={msg.type}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {
                            msg.type === "success" ?
                            language === "alb" ?
                            'Email-i u dërgua me sukses!'
                            :
                            'Email sent succesfully!'
                            :
                            language === "alb" ?
                            'Email-i nuk u dergua ju lutem mbushni te gjitha format siq duhet'
                            :
                            'Email was not sent. Make sure fomr is filled correctly!'
                        }
                    </Alert>
                </Snackbar>
            }
        </Box>
    )
}