import React from "react";
import { useSelector } from "react-redux";
import ActivitiesBanner from "../../../components/activities/ActivitiesBanner";
import TextWrapper from "../../../components/general/text/TextWrapper";
import style from "./Activities.module.css";
import lng from "../../../utils/language";
import ActivitiesInterest from "../../../components/activities/ActivitiesInterest";
// import RadiantBanner from "../../../components/general/banner/RadiantBanner";
// import Grid from "../../../layout/grid/Grid";
import BigCards from "../../../components/card/BigCard";
import constants from "../../../utils/consts";
import AppBanner from "../../../components/general/banner/AppBanner";
// import ReserveText from "../../../components/reservation/ReserveText";
import Button from '@mui/material/Button';
// import MonthlyChampions from "../../../components/activities/MonthlyChampions";
import GeneralLayout from "../../../layout/activities/GeneralLayout";
import { useRef, useState } from "react";
import { Box, Typography } from "@mui/material";
import CarouselGallery from "../../../components/carousel/CarouselGallery";
// import FullBanner from "../../../components/general/banner/FullBanner";
import RandomCards from "../../../components/card/RandomCards";
import RaceEventCard from "../../../components/carousel/RaceEventCard";
import { laser } from "../../../utils/gameLng";
// import { AnimatePresence, motion } from "framer-motion";
import FetchData from "../../../api/FetchData";
import { Link } from "react-router-dom";
import Videos from "../../../components/general/videos/Videos";
import WaitingLoader from "../../../components/general/loader/WaitingLoader";
import PartyBanner from "../../../components/general/banner/PartyBanner";
import Text from "../../../components/text/Text";

function Laser() {
    const { language } = useSelector((state) => state.language);
    const gallery = useRef(null);
    const games = useRef(null);
    const [laserData, setLaserData] = useState();
    const [loading, setLoading] = useState(true)
    const load = () => {
        FetchData.create('/data/getAll', {
            id: 2,
            lng: language === "alb" ? 1 : 2
        })
            .then(res => {
                setLaserData(res.data.data);
                setLoading(false);
            })
            .catch(err => {
            })
    }
    React.useEffect(() => {
        load();
        // eslint-disable-next-line
    }, [language])
    const containerSpacing = {
        paddingY: '80px'
    }
    const interest = {
        eng: {
            interest: [
                {
                    interest: "Gallery",
                    ref: gallery,
                },
                {
                    interest: "Types of Battles",
                    ref: games,
                }
            ]
        },
        alb: {
            interest: [
                {
                    interest: "Galeria",
                    ref: gallery,
                },
                {
                    interest: "Llojet e betejave",
                    ref: games,
                }
            ]
        }
    }

    return (

        loading === false ?
            laserData &&

            <div className={style.detailsWrapper}>
                <div className="container">
                    {
                        laserData[1] &&
                        <ActivitiesBanner
                            title={laserData[1].body_header}
                            desc={laserData[1].body_desc}
                            image={constants.imgPath + laserData[1].images}
                            linkPrice="karting"
                            vidId="f4hBVh_phw8"
                            // id={laserData[1].link}
                            prices={true}
                        />
                    }
                    <ActivitiesInterest
                        interests={interest[language].interest}
                    />

                    <Box sx={{ height: { md: '500px', sm: '416px', xs: '209px' }, position: 'relative', marginBottom: '100px', marginTop: '150px' }}>
                        <Box sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0, width: 1, height: 1, backgroundColor: 'transparent' }} />
                        <Videos id={'f4hBVh_phw8'} />
                    </Box>
                </div>
                {
                    laserData[6] &&
                    <Box sx={containerSpacing} ref={gallery}>
                        <CarouselGallery gallery={laserData[6][0]['images']} radius={5} />
                        
                    </Box>
                }

                <Box sx={containerSpacing}>
                    <PartyBanner
                        header={lng[language]['hyperBirthdayHeader']}
                        desc={lng[language]['hyperBirthdayDescBig']}
                        secondHeader={lng[language]['hyperBirthdayDescBigSH']}
                        buttonTxt={lng[language]['bookNow']}
                        link={"/activities/party"}
                        imageUrl={"https://hyper-active.com/images/lasertag/banner/Group 2354.png"}
                        containerStyle={{
                            width: { sm: "454px", xs: '90%' },
                            color: "#FFF",
                            padding: 3,
                            borderRadius: "20px",
                            display: "grid",
                            gridTemplateRows: "0fr 0fr 0fr 0fr",
                            rowGap: "20px",
                            '.secondHeader': {
                                fontWeight: 'bold'
                            }
                        }}
                        imgStyling={{
                            height: {
                                sm: 1,
                                xs: '110%'
                            }
                        }}
                        headerStyle={{
                            fontWeight: 'bold',
                            fontSize: '40px'
                        }}
                        buttonStyle={{
                            height: 'max-content',
                            backgroundColor: '#AF46FA',
                            border: 'solid 1px #AF46FA',
                            paddingY: '20px',
                            paddingX: '60px',
                            borderRadius: '40px',
                            marginTop: {
                                sm: '50px',
                                xs: '20px'
                            },
                            '&:hover': {
                                color: '#AF46FA'
                            }
                        }}
                    />
                </Box>

                <div className="container">
                    {
                        <Box sx={containerSpacing} ref={games}>
                            <Box sx={{ marginBottom: 10 }}>
                                <Text
                                    header={laser[language]['battleType']}
                                    desc={laser[language]['battleTypeDesc']}
                                    headerStyle={{
                                        fontWeight: '600',
                                        textAlign: 'center',
                                        marginBottom: '20px'
                                    }}
                                    descStyling={{
                                        whiteSpace: {
                                            md: 'pre-line',
                                            xs: 'normal'
                                        },
                                        textAlign: 'center'
                                    }}
                                />
                            </Box>

                            {
                                laserData[8] &&
                                <Box sx={{ display: 'grid', gridTemplateColumns: { md: '1fr 1fr', xs: '1fr' }, columnGap: '20px', rowGap: '50px' }}>
                                    {laserData[8].map((i, j) => {
                                        return (
                                            <BigCards
                                                data={i}
                                                key={j}
                                                noBlank={true}
                                                reserveType={true}
                                                buttonStyle={{
                                                    padding: '10px 30px !important',
                                                    border: 'solid 2px #AF46FA !important',
                                                    '&:hover': {
                                                        backgroundColor: '#AF46FA'
                                                    }
                                                }}
                                            />
                                        )
                                    })}
                                </Box>
                            }
                        </Box>
                    }

                    {
                        laserData[10] &&
                        <div className={style.sectionContainer}>
                            <TextWrapper
                                title={lng[language]['racesAndEvents']}
                            />
                            <RaceEventCard arr={laserData[10]} type={true} />
                        </div>
                    }
                </div>

                <Box className="container">
                    <Box sx={containerSpacing}>
                        {
                            laserData[9] &&
                            <div sx={containerSpacing}>
                                <GeneralLayout
                                    data={laserData[9]}
                                    specialStyling={{
                                        backgroundColor: 'transparent'
                                    }}
                                    txtContainerStyling={{
                                        height: {
                                            md: '290px',
                                            xs: '80%'
                                        },
                                        marginY: 'auto',
                                        '.MuiTypography-h1': {
                                            fontSize: '30px'
                                        },
                                        '.MuiTypography-p': {
                                            fontSize: '16px'
                                        }
                                    }}
                                />
                            </div>
                        }
                    </Box>
                    <div className="container">
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <Text 
                                header={lng[language]['raceRules']}
                            />
                            <Button 
                                sx={{
                                    marginTop: '30px',
                                    background: '#AF46FA',
                                    borderRadius:'40px',
                                    padding: '15px 30px',
                                    '&:hover': {
                                        background: '#FFF',
                                        color: '#AF46FA'
                                    }
                                }} 
                                variant="contained" 
                                LinkComponent={Link} 
                                to='/activities/rules'
                            >
                                {lng[language]['learnMore']}
                            </Button>
                        </Box>

                            <Box sx={containerSpacing}>
                                <AppBanner
                                    header={lng[language]['hyperAppHeader']}
                                    desc={lng[language]['hyperAppDesc']}
                                    imgUrl={'../images/6582cf3c6d165.webp'}
                                />
                            </Box>
                        
                        <Box className="container" paddingTop={5} paddingBottom={5}>
                            <Box sx={{ width: { lg: '60%', xs: '100%' }, marginX: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <Typography variant="h3" textAlign={'center'} marginBottom={3} sx={{fontSize: {md: '3rem', xs: '40px'}}}>Let's Heat That Laser Gun</Typography>
                                <Typography variant="body1" marginBottom={3} textAlign={'center'} width={'90%'}>{laser[language]['reservetXT']}</Typography>
                                <Button LinkComponent={Link} to={'/reservation/739734'} variant="contained" sx={{ background: '#AF46FA', transition: '.2s all', borderRadius: '40px', paddingX: 5, paddingY: 1.4, '&:hover': { backgroundColor: '#FFF', color: '#AF46FA' } }}>
                                    {lng[language]['bookNow']}
                                </Button>
                            </Box>
                        </Box>

                        <Box paddingBottom={5} paddingTop={5} sx={containerSpacing}>
                            <Box sx={{textAlign: 'center'}}>
                                <TextWrapper title={lng[language]['gameSuggestion']} />
                            </Box>
                            <RandomCards removeGame='Laser Tag' />
                        </Box>
                    </div>
                </Box>
            </div>
            :
            <WaitingLoader open={loading} />
    );
}
export default Laser;
