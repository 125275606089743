import { updateToken } from "../app/auth/Auth";
import { api } from "./config";
import { store } from "../app/store";
import { removePlayerData } from "../app/cart/Cart";
import { jwtDecode } from "jwt-decode";

export const setToken = (user, token, exp) => {
    var myDate = new Date(exp);
    const tokenExpire = myDate.getTime();
    localStorage.setItem("token", token);
    localStorage.setItem("user", user);
    localStorage.setItem("exp", tokenExpire);
};

export const removeToken = () => {
    if (localStorage.getItem("token")) localStorage.removeItem("token");
    if (localStorage.getItem("token")) localStorage.removeItem("user");
    if (localStorage.getItem("exp")) localStorage.removeItem("exp");
    if (localStorage.getItem("cart")) localStorage.removeItem("cart");
    //Get temp token: 
    initiateTempToken();
    //Everytime we remove token initiate temp. token
};

export const isTokenValid = () => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");
    const expTime = localStorage.getItem("exp");
    if ((!token || token === "undefined") || (!expTime || expTime === isNaN)) {
        return { isValide: false, };
    }
    try {
        let decodedToken = jwtDecode(token);
        let currentDate = new Date() / 1000;
        // console.log({
        //     exp: decodedToken.exp,
        //     curr: currentDate,
        //     success: decodedToken.exp < currentDate,
        // });
        if (decodedToken.exp < currentDate) {
            removeToken();
            return { isValide: false };
        }else{
            return { isValide: true, user: user, token: token, expTime: expTime };
        }
    } catch (err) {
        // removeToken();
        return { isValide: false };
    }
};

export const initiateTempToken = () => {
    api.get("/auth/token").then((res) => {
        if (res.data.hasError === false) {
            store.dispatch(updateToken({
                token: res.data.token,
                exp: res.data.exp
            }));
            store.dispatch(removePlayerData())
            setToken(null, res.data.token, res.data.exp);
        }
    })
        .catch((error) => {
            console.error(error);
        });
}