const lng = {
  eng: {
    failLogin: 'Your email or password is incorrect',
    racesAndEvents: "Races and Events",
    racesAndEventsDesc: "We have prepared racing events and events like player of the month and driver of the month.",
    name: "First Name",
    lastname: "Last Name",
    fullname: "Full Name",
    email: "Email",
    password: "Password",
    dob: "Date of Birth",
    mob: "Phone Number",
    required: "Please fill out this field!",
    invalid: "Please enter a valid value",
    short: "This value is to short!",
    long: "This value is to long!",
    invalidMail: "Email address is not valid!",
    invalidPhone: "Phone number is not valid!",
    strongPassword: "Please enter a strong password!",
    login: "Login",
    register: "Register",
    forgotPsw: "Forgot Password?",
    noAccount: "You don't have an account?",
    doNotMatch: "Passwords must match!",
    acceptTerms: "Please accept our terms & conditions!",
    passwordConfirmation: "Password Confirmation",
    personalNo: "ID Number",
    city: "City",
    country: "Country",
    optionsError: "Please select one of the options!",
    verifyMail: "Verify your email",
    verifyMailToRegister: "You will need to verify your email to complete registration!",
    successVerification: "Activation was successful!",
    errorVerification: "Sorry, this link is no longer valid",
    errorVerificationMessage: "Oops! The email verification link is not valid anymore!",
    successVerificationMessage: "Your account was activated successfully!",
    mailExists: "This Email Address is already used",
    phoneExists: "This Phone Number is already used",
    resendVerification: "Resend the link",
    contactSupport: "Contact Support!",
    sendLink: "Send link",
    linkSuccess: "Link send successfully. Please check your email address!",
    writeMail: "Please enter your email",
    changePassword: "Change Password",
    forgetPasswordError: "This user does not have an account or is not verified!",
    verifyAccount: "Please check your email address to activate your account!",
    change: "Save Changes",
    gender: "Gender",
    passwordChanges: "Your password has been changed succesfully!",
    safetyVid: 'Safety Briefing Video',
    // GENERAL
    prepretaionGame: 'How to prepare for the game',
    bookNow: "Book Now",
    seePrices: "You can see our price list",
    seePriceLink: " HERE",
    gameRules: "Safety First",
    gameSuggestion: "Other game suggestions",
    games: 'Games',
    openModal: "Open",
    closeModal: "Close",
    watchVideo: "Watch Video",
    showMore: "Show more",
    showLess: "Show less",
    openHour: "Opening Hours",
    checkResulsts: "View results",
    // racesAndEvents: "Races And Events",
    bestPlayers: 'We have prepared competition events and events like the best player of the month and the best team of the month.',
    clickInterest: "Click interests",
    reserveBtn: `We recommend making a reservation for each attraction so you don't have to wait
    in order for an extraordinary experience.`,
    homepageKarting: `Racers get ready for full adrenaline experiences!

    Experience professional level driving on the 500m indoor track with the all-electric Gokart-RSX2, safe and with impressive performance⚡️
    
    Adventure awaits you!`,
    gallery: 'Gallery',
    category: 'Category',
    whoCanDrive: 'Who can drive?',
    progress: 'Progress',
    soon: 'Soon',
    hyperActiveAppDesc: 'View balance, top up balance, make all bookings and earn bonuses. All this and more at your fingertips very quickly with the Hyperactive App.',
    safetyAboveAll: 'Safety Above All',
    bestChampions: 'Be part of the race',
    lodraHeader: 'Experience the super day of fun at LODRA!',
    lodraDesc: `Endless opportunities for adventure and exploration, with soft play equipment, obstacle courses and imaginative play areas to keep your child engaged and entertained.

    When it's time to take a break, indulge in delicious desserts. With a team of friendly staff ready to assist you and your family, you will have a stress-free and memorable day.`,
    partyNow: 'Celebrate Now',
    gameTypes: 'Types of games'
  },
  alb: {
    failLogin: 'Email-i ose Fjalkalimi juaj është gabimë',
    racesAndEvents: "Races and Events",
    racesAndEventsDesc: "We have prepared racing events and events like player of the month and driver of the month.",
    passwordChanges: "Fjalëkalimi u ndryshua me sukses!",
    name: "Emri",
    lastname: "Mbiemri",
    fullname: "Emri i plotë",
    email: "Email Adresa",
    password: "Fjalëkalimi",
    dob: "Data e lindjes",
    mob: "Numri kontaktues",
    required: "Kjo fushë është e domosdoshme!",
    short: "Vlera duhet të jetë më e gjatë",
    invalid: "Vlera e shënuar nuk është valide!",
    long: "Vlera duhet të jetë max.50 karaktere! ",
    invalidMail: "Email Adresa nuk është valide!",
    invalidPhone: "Numri kontaktues nuk është valid!",
    strongPassword: "Shënoni një fjalëkalim më të sigurtë!",
    login: "Kyçu",
    register: "Regjistrohu",
    forgotPsw: "Keni harruar fjalëkalimin?",
    noAccount: "Nuk keni llogari?",
    doNotMatch: "Fjalëkalimet nuk përputhen!",
    acceptTerms: "Ju lutem pajtohuni me kushtet e privatësië!",
    passwordConfirmation: "Rishtyp fjalëkalimin",
    personalNo: "Numri identifikues",
    city: "Qyteti",
    country: "Shteti",
    optionsError: "Ju lutem zgjedhni njërin nga opsionet!",
    verifyMail: "Verifikoni Email Adresën",
    verifyMailToRegister: "Ju duhet të verifikoni email adresën për të kompletuar regjistrimin!",
    successVerification: "Verifikimi u krye me sukses!",
    errorVerification: "Ju kërkojmë falje, linku nuk është valid!",
    successVerificationMessage: "Llogaria juaj u aktivizua me sukses!",
    errorVerificationMessage: "Oops! Linku për verifikimin e llogarisë suaj nuk është valid!",
    mailExists: "Email Adresa tashmë ka llogari",
    phoneExists: "Numri kontaktues ka llogari",
    resendVerification: "Ridërgo linkun në email",
    contactSupport: "Kontaktoni njërin nga ekipa jonë!",
    sendLink: "Dërgo Linkun",
    writeMail: "Ju lutem shënoni email adresën",
    changePassword: "Ndrysho Fjalëkalimin",
    forgetPasswordError: "Kjo email adresë nuk ka llogari apo nuk është verifikuar akoma!",
    linkSuccess: "Linku u dërgua me sukses. Ju lutem kontrolloni email adresën!",
    verifyAccount: "Ju lutem kontrolloni email adresën për të aktivizuar llogarinë tuaj!",
    change: "Ruaj Ndryshimet",
    safetyVid: 'Video e sigurisë',
    // GENERAL
    prepretaionGame: 'Si te pergaditeni per lojë',
    bookNow: "Rezervo",
    seePrices: "Shikoni çmimet tona",
    seePriceLink: "Këtu",
    gameRules: "Rregullat e lojës",
    gameSuggestion: "Sygjerime për lojëra të tjera",
    games: 'Lojërat',
    openModal: "Open",
    closeModal: "Close",
    watchVideo: "Shiko Videon",
    showMore: "Shiko me shumë",
    showLess: "Shiko me pakë",
    gender: "Gjinia",
    openHour: "Orari i punës",
    checkResulsts: "Shiko rezultatet",
    racesAndEvents: "Garat Dhe Eventet",
    bestPlayers: 'Ne kemi përgatitur evente garash dhe ngjarje si lojtari më i mirë i muajit dhe ekipi më I mirë i muajit.',
    clickInterest: "Kliko Interesat",
    reserveBtn: `Ju rekomandojmë rezervimin për një përvojë të jashtëzakonshme`,
    homepageKarting: `Garues bohuni gati për eksperienca plotë adrenalinë!

    Përjetoni vozitjen e nivelit profesional në pistën e brendshme 500m me Gokart- RSX2 krejt elektrik, i sigurtë dhe me performancë mbresëlënëse⚡️
    
    Aventura ju pret!`,
    gallery: 'Galeria',
    category: 'Kategoria',
    whoCanDrive: 'Kush mund të drejtojë makinën?',
    progress: 'Progresi',
    soon: 'Së Shpejti',
    hyperActiveAppDesc: 'Shikoni bilancin, mbushni bilancin, kryeni të gjitha rezervimet dhe fitoni bonuse. Të gjitha këto dhe më shumë në majet e gishtit shumë shpejtë me Hyperactive App.',
    safetyAboveAll: 'Siguria Para Të Gjithave',
    bestChampions: 'Bëhu pjesë e garës',
    lodraHeader: 'Përjetoni super ditën e argëtimit në LODRA!',
    lodraDesc: `Mundësi të pafundme për aventura dhe eksplorime, me pajisje të buta për lojëra, fusha me pengesa dhe zona me imagjinatë lojërash për ta mbajtur fëmijën tuaj të angazhuar dhe argëtuar.

    Kur është koha për të bërë një pushimm, kënaquni me ëmbëlsirat e shijshme. Me një ekip të stafit miqësor të gatsheëm për t'ju ndihmuar ju dhe familjen tuaj, do të kaloni një ditë pa stres dhe të paharrueshme.`,
    partyNow: 'Festo Tani',
    gameTypes: 'Llojet e lojërave'
  },
};

export default lng;