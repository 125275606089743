import { useSelector } from "react-redux";
import ActivitiesBanner from "../../../components/activities/ActivitiesBanner";
import TextWrapper from "../../../components/general/text/TextWrapper";
import style from "./Activities.module.css";
import lng from "../../../utils/language";
import ActivitiesInterest from "../../../components/activities/ActivitiesInterest";
import Grid from "../../../layout/grid/Grid";
import SingleBanner from "../../../layout/activities/SingleBanner";
import constants from "../../../utils/consts";
import AppBanner from "../../../components/general/banner/AppBanner";
// import ReserveText from "../../../components/reservation/ReserveText";
import React, { useRef } from "react";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import CarouselGallery from "../../../components/carousel/CarouselGallery";
import RandomCards from "../../../components/card/RandomCards";
// import { useState } from "react";
// import { escape } from "../../../utils/gameLng";
// import { AnimatePresence, motion } from 'framer-motion';
import FetchData from "../../../api/FetchData";
import ImageTextCard from "../../../components/card/ImageTextCard";
import PartyBanner from "../../../components/general/banner/PartyBanner";
import Videos from "../../../components/general/videos/Videos";
import WaitingLoader from "../../../components/general/loader/WaitingLoader";
// import Text from "../../../components/text/Text";

function EscapeRoom() {
    const { language } = useSelector((state) => state.language);

    const gallery = useRef(null);
    const escapeRoom1 = useRef(null);
    const escapeRoom2 = useRef(null);
    const escapeRoom3 = useRef(null);
    const escapeRoom4 = useRef(null);
    
    const lostCityOfAtlantis = useRef(null);
    const nightmareHotel = useRef(null);
    const orientExpress = useRef(null);
    const walkingDeath = useRef(null);
    const theOrphanage = useRef(null);
    const horrorCircus = useRef(null);
    // const [show1, setShow1] = useState(false);
    // const [show2, setShow2] = useState(false);
    // const [show3, setShow3] = useState(false);
    // const [show4, setShow4] = useState(false);

    const [gameData, setGameData] = React.useState();
    const [loading, setLoading] = React.useState(true)

    const load = () => {
        FetchData.create('/data/getAll', {
            id: 6,
            lng: language === "alb" ? 1 : 2
        })
            .then(res => {
                setGameData(res.data.data)
                setLoading(false);
            })
            .catch(err => {
            })
    }

    React.useEffect(() => {
        load();
        // eslint-disable-next-line
    }, [language])

    // const reverse = (state, setstate) => {
    //     setstate(!state);
    // }

    const interest = {
        eng: {
            interest: [
                {
                    interest: 'Gallery',
                    ref: gallery
                },
                {
                    interest: 'LA MISION DEL PROFESOR',
                    ref: escapeRoom1
                },
                {
                    interest: 'Haunted Mansion',
                    ref: escapeRoom2
                },
                {
                    interest: 'School of Magic',
                    ref: escapeRoom3
                },
                {
                    interest: '1001 Nights',
                    ref: escapeRoom4
                },
                // NEW ROOMS
                {
                    interest: 'THE ORPHANAGE',
                    ref: theOrphanage
                },
                {
                    interest: 'HORROR CIRCUS',
                    ref: horrorCircus
                },
                {
                    interest: 'LOST CITY OF ATLANTIS',
                    ref: lostCityOfAtlantis
                },
                {
                    interest: 'NIGHTMARE HOTEL',
                    ref: nightmareHotel
                },
                {
                    interest: 'ORIENT EXPRESS',
                    ref: orientExpress
                },
                {
                    interest: 'WALKING DEATH',
                    ref: walkingDeath
                }
            ]
        },
        alb: {
            interest: [
                {
                    interest: 'Galeria',
                    ref: gallery
                },
                {
                    interest: 'LA MISION DEL PROFESOR',
                    ref: escapeRoom1
                },
                {
                    interest: 'Haunted Mansion',
                    ref: escapeRoom2
                },
                {
                    interest: 'School of Magic',
                    ref: escapeRoom3
                },
                {
                    interest: '1001 Nights',
                    ref: escapeRoom4
                },
                 // NEW ROOMS
                 {
                    interest: 'THE ORPHANAGE',
                    ref: theOrphanage
                },
                {
                    interest: 'HORROR CIRCUS',
                    ref: horrorCircus
                },
                {
                    interest: 'LOST CITY OF ATLANTIS',
                    ref: lostCityOfAtlantis
                },
                {
                    interest: 'NIGHTMARE HOTEL',
                    ref: nightmareHotel
                },
                {
                    interest: 'ORIENT EXPRESS',
                    ref: orientExpress
                },
                {
                    interest: 'WALKING DEATH',
                    ref: walkingDeath
                }
            ]
        }
    }

    const bigCard = [
        {
            logo: 'https://hyper-active.com/images/content/nightmare_hotel.svg',
            imgSrc: constants.imgPath + "nightmare_hotel.png",
            title: {
                eng: "NIGHTMARE HOTEL",
                alb: "NIGHTMARE HOTEL",
                referenc: nightmareHotel
            },
            desc: {
                alb: "Bëhuni anëtar i ekipit të famshëm, për të përfunduar vjedhjen e parave dhe grabitjen e minteve kombëtare. Shmangni sigurimit dhe printoni sa më shumë para që mundeni.",
                eng: "Become a member of the famous team, to complete the theft of money and the robbery of national mints. Avoid insurance and print as much money as you can.",
            },
            list: {
                eng: {
                    Category: "Horror",
                    "Player No.": "2-6",
                    Time: "60min",
                    Mysteries: "13",
                },
                alb: {
                    Kategoria: "Horror",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    Misteret: "13",
                },
            },
            longDesc: {
                alb: `Ju dhe miqtë tuaj nisët udhëtimin tuaj në fshat me humorin më të mirë. Por një kthesë e gabuar në rrugë ju ka bërë të humbisni në natyrë. Errësira kishte rënë kur papritmas,
                    vërejtët një burim drite. Teksa iu afruat, patë një ndërtesë imponuese nga një epokë tjetër
                    dhe një tabelë të madhe mbi derën e hyrjes: HOTELI I ËNDRRAVE! Vendosët që të kaloni 
                    natën këtu, por kur dyert e tij u mbyllën pas jush, e dinit tashmë se Ëndrra do të kthehej në 
                    një Makth!`,
                eng: `You and your friends started your trip to the countryside in the best of moods. But a wrong turn on the road has caused you to get lost in nature. Darkness had fallen when suddenly,
                    you noticed a light source. As you approached, you saw an imposing building from another era
                    and a big sign above the front door: DREAM HOTEL! You decided to pass
                    night here, but when its doors closed behind you, you already knew that the Dream would return to
                    a Nightmare!`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/orient_express.svg',
            imgSrc: constants.imgPath + "orient_express.png",
            title: {
                eng: "ORIENT EXPRESS",
                alb: "ORIENT EXPRESS",
                referenc: orientExpress
            },
            desc: {
                alb: "Ka një vrasës në tren! Poirot ju jep një orë për të gjetur provat. A do të jeni ju që do të zgjidhni misterin?",
                eng: "There's a killer on the train! Poirot gives you one hour to find the evidence. Will you be the one to solve the mystery?",
            },
            list: {
                eng: {
                    Category: "Mystery",
                    "Player No.": "2-6",
                    Time: "60min",
                    Mysteries: "14",
                },
                alb: {
                    Kategoria: "Mister",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    Misteret: "14",
                },
            },
            longDesc: {
                alb: `Ka një vrasës në këtë tren... Ose më shumë se një! Që nga dje, 2 nga pasagjerët janë zhdukur, dhe vetëm mbrëmë kemi gjetur trupin e pajetë të njërit prej tyre. Zoti Hercule Poirot këshilloi personin përgjegjës dhe Oficerin e Policisë së trenit, të mbledhin të gjithë, përfshirë edhe atë vetë, në restorantin kryesor të trenit. 
    
    Megjithatë, para se të mbyllej dera e restorantit, ju morët një mesazh nga Z. Poirot që thoshte: "Mos hajde në restorant me të tjerët. Nëse nuk gaboj, vrasësi mund të jetë këtu me ne, por prova e fajësisë së tij nuk është. Kam lënë një letër për ty, me të gjitha informacionet që di deri tani dhe do t'i vonoj të gjithë për një orë, duke pretenduar se po i marr në pyetje, kështu që ti ta gjesh vrasësin! Fat të mbarë."`,
               
                eng: `There's a killer on this train... Or more than one! Since yesterday, 2 of the passengers have disappeared, and only last night we found the dead body of one of them. Mr. Hercule Poirot advised the person in charge and the Train Police Officer to gather everyone, including himself, in the main restaurant of the train.
    
    However, before the restaurant door closed, you received a message from M. Poirot saying, "Do not come into the restaurant with the others. If I am not mistaken, the murderer may be here with us, but the proof of his guilt is not . I've left a letter for you, with all the information I know so far, and I'll delay everyone for an hour, pretending I'm interrogating them, so you can find the killer!`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/walking_death.svg',
            imgSrc: constants.imgPath + "walking_death.png",
            title: {
                eng: "WALKING DEATH",
                alb: "WALKING DEATH",
                referenc: walkingDeath
            },
            desc: {
                alb: "Ekipi juaj ka mbijetuar ferrin, duke luftuar kundër të vdekurve dhe armiqve të gjallë. Tani, në këtë burg të braktisur, po krijoni një vendbanim të ri.",
                eng: "Your team has survived hell, fighting against dead and living enemies. Now, in this abandoned prison, you are creating a new settlement.",
            },
            list: {
                eng: {
                    Category: "Horror",
                    "Player No.": "2-6",
                    Time: "60min",
                    Mysteries: "15",
                },
                alb: {
                    Kategoria: "Horror",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    Misteret: "15",
                },
            },
            longDesc: {
                alb: `Ekipi juaj ka mbijetuar ferrin, duke luftuar jo vetëm kundër të vdekurve, por edhe kundër njerëzve armiqësor. Një javë më parë, keni gjetur këtë burg të braktisur. Kishit fat që eliminuat disa zombie të ngecur aty. Tani po përpiqeni të krijoni një vendbanim, duke riparuar fortifikatat dhe duke eksploruar hap pas hapi çdo seksion. Sot, Seksioni 3, Sektori i Izolimit është Caku juaj...`,
               
                eng: `Your team has survived hell, fighting not only against the dead, but also against hostile humans. A week ago, you found this abandoned prison. You were lucky to eliminate some zombies stuck there. Now you are trying to create a settlement, repairing fortifications and exploring step by step each section. Today, Section 3, Isolation Sector is your Target...`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/ERArtwork-10.svg',
            imgSrc: constants.imgPath + "Casa.jpg",
            title: {
                eng: "LA MISIÓN DEL PROFESOR",
                alb: "LA MISIÓN DEL PROFESOR",
                referenc: escapeRoom1
            },
            desc: {
                alb: "Bëhuni anëtar i ekipit të famshëm, për të përfunduar vjedhjen e parave, grabitjen e minteve kombëtare. Shmangni sigurinë dhe printoni sa më shumë para që mundeni.",
                eng: "Be a member of the famous crew, to complete the Money Heist, Robbing the National Mint. Avoid the security , and print as much money as you can.",
            },
            list: {
                eng: {
                    Category: "Adventure",
                    "Player No.": "2-6",
                    Time: "60min",
                    Mysteries: "17",
                },
                alb: {
                    Kategoria: "Aventurë",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    Misteret: "17",
                },
            },
            longDesc: {
                alb: `Ka ardhur koha e grabitjes më të madhe të të gjitha kohërave, ndaj vendosni maskat dhe uniformat dhe tregoni se çfarë jeni në gjendje të bëni. Plani është hartuar tërësisht dhe paratë ju presin, por kur dyert e Mint-it të mbyllen, ekipi juaj do të testohet si kurrë më parë. 
                
                A do të jeni në gjendje të qëndroni të bashkuar nën presionin mbytës dhe zgjedhje të vështira, apo do të thyheni dhe do të ktheheni kundër njëri-tjetrit? Plani i Profesorit tashmë është vënë në jetë dhe rreziku më i madh jeni ju.`,
                eng: `The time for the biggest robbery of all time has reached, so put your masks and uniforms and show what you are capable of doing. The plan is thoroughly designed and the money awaits you, but when the Mint doors are closed, your team will be tested as never again. 
                
                Will you be able to remain united under stifling pressure and difficult choices, or will you break and turn against each other? The Profesor’s plan has already been put into practice, and the greatest danger is yourself.`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/lost_city_of_atlantis.svg',
            imgSrc: constants.imgPath + "lost_city_of_atlantis.png",
            title: {
                eng: "LOST CITY OF ATLANTIS",
                alb: "LOST CITY OF ATLANTIS",
                referenc: lostCityOfAtlantis
            },
            desc: {
                alb: "Pas shumë vite kërkimesh, keni arritur këtu! Zbuloni rrënojat e Atlantidës dhe Tridentin e Poseidonit. A jeni gati të rishkruani Historinë e Botës dhe të përballeni me rreziqet e panjohura që fshihen nën ujë? Bashkohuni në këtë aventurë epike!",
                eng: "After many years of searching, you have arrived here! Discover the ruins of Atlantis and the Trident of Poseidon. Are you ready to rewrite the History of the World and face the unknown dangers that lurk under the water? Join this epic adventure!",
            },
            list: {
                eng: {
                    Category: "Mythology",
                    "Player No.": "2-6",
                    Time: "60min",
                    Mysteries: "15",
                },
                alb: {
                    Kategoria: "Mitologji",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    Misteret: "15",
                },
            },
            longDesc: {
                alb: `Tregimi i Tridentit të Poseidonit, është një nga legjendat më të mëdha të detit. Sipas mitit, Poseidoni ishte xheloz për Atlantidën, kështu që ai fundos qytetin tërësisht për ta mbajtur për vete nën ujë. Skeptari i tij i fuqishëm, "Tridenti", ishte ruajtur në qendër të Pallatit të Atlantidës. Dhe ju? Pas shumë vite kërkimesh dhe përkthimesh të qindra shkrimeve të lashta, jeni këtu! Nëse keni të drejtë, zbulimi i Atlantidës është një fakt që do të rishkruajë Historinë e Botës. Rrënojat e lashta që po shikoni tani, fshehin mistere shumë më të mëdha se çdo legjendë e njohur dhe gjithashtu rrezikun e zhdukjes tuaj me vetë Atlantidën!`,
               
                eng: `The story of Poseidon's Trident is one of the greatest legends of the sea. According to the myth, Poseidon was jealous of Atlantis, so he sunk the city entirely to keep it under water for himself. His powerful scepter, the "Trident", was kept in the center of the Palace of Atlantis. You too? After many years of research and translations of hundreds of ancient writings, you are here! If you are right, the discovery of Atlantis is a fact that will rewrite the history of the world. The ancient ruins you are viewing now hide mysteries far greater than any known legend and also the danger of your extinction with Atlantis itself!`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/the_ophanage.svg',
            imgSrc: constants.imgPath + "the_ophanage.png",
            title: {
                eng: "THE ORPHANAGE",
                alb: "THE ORPHANAGE",
                referenc: theOrphanage
            },
            desc: {
                alb: "Ju dhe ekipi keni një orë për të zbuluar të vërtetën dhe për të shpëtuar shpirtin e murgeshës. A do të arrini ta bëni?",
                eng: "You and the team have one hour to uncover the truth and save the nun's soul. Will you manage to do it?",
            },
            list: {
                eng: {
                    Category: "Horror",
                    "Player No.": "2-6",
                    Time: "60min",
                    Mysteries: "12",
                },
                alb: {
                    Kategoria: "Horror",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    Misteret: "12",
                },
            },
            longDesc: {
                alb: `Të gjithë e dinë për atë jetimoret dhe shfrytëzimin mizor të fëmijëve, por pa dobi. Morali që ju dallon juve dhe ekipin tuaj ju çoi në vendin tashmë të braktisur, me qëllimin e vetëm për të zbuluar se çfarë kishte ndodhur atëherë. Keni një orë për të mbledhur sa më shumë prova të mundeni dhe për t'i shpëtuar shpirtit të murgeshës një herë e përgjithmonë.`,
               
                eng: `Everyone knows about the orphanages and the cruel exploitation of children, but to no avail. The morale that distinguishes you and your team led you to the now abandoned place, with the sole purpose of finding out what had happened then. You have one hour to gather as much evidence as you can and escape the nun's soul once and for all.`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/ERArtwork-9.svg',
            imgSrc: constants.imgPath + "potter1.jpg",
            title: {
                eng: "SCHOOL OF MAGIC",
                alb: "SCHOOL OF MAGIC",
                referenc: escapeRoom3
            },
            desc: {
                eng: "An escape game adventure with extremely technologically advanced features and games, plenty of wow factors and visual effects that create a unique real-life experience.",
                alb: "Një aventurë arratisjeje me veçori dhe lojëra jashtëzakonisht të avancuara të teknologjisë, shumë faktorë dhe efekte vizuale, që krijojnë një përvojë unike të jetës reale.",
            },
            list: {
                eng: {
                    Category: "Adventure",
                    "Player No.": "2-6",
                    Time: "60min",
                    // Mistery: "18",
                    Mysteries: "18",
                },
                alb: {
                    Kategoria: "Aventurë",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    // Mister: "18",
                    Misteret: "18",
                },
            },
            longDesc: {
                eng: `The Dark One has awoken. Only the power of the Enchanted Scarlet Gem, hidden inside a secret chamber full of dangerous spells and perilous witchcrafts, can put an end to his reign of terror. You and your team of warlocks and witches must retrieve the Gem and defeat the rising darkness, or all will be lost. Make haste, for time is not on your side.`,
                alb: `I errëti është zgjuar. Vetëm fuqia e Perlës së Magjepsur të Scarlet, e fshehur brenda një dhome të fshehtë plot me magji të rrezikshme, mund t'i japë fund terrorist të tij. Ju dhe ekipi juaj i magjistarëve dhe shtrigave duhet të merrni Gem-in dhe të mposhtni errësirën në rritje, ose gjithçka do të humbasë. Nxitoni, sepse koha nuk është në anën tuaj.`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/horror_circus.svg',
            imgSrc: constants.imgPath + "horror_circus.png",
            title: {
                eng: "HORROR CIRCUS",
                alb: "HORROR CIRCUS",
                referenc: horrorCircus
            },
            desc: {
                alb: "Një aventurë e tmerrshme nën rreze cirkut të mallkuar! Tani, ju duhet të gjeni një rrugë për të shpëtuar para se të jetë vonë. A t'i çoni përpara hijet errëta dhe figurat e frikshme në këtë histori?",
                eng: "A terrifying adventure under the rays of the damned circus! Now, you must find a way to escape before it's too late. Bring forward the dark shadows and scary figures in this story?",
            },
            list: {
                eng: {
                    Category: "Horror",
                    "Player No.": "3-6",
                    Time: "60min",
                    Mysteries: "16",
                },
                alb: {
                    Kategoria: "Horror",
                    "Numri i lojtarëve.": "3-6",
                    Kohëzgjata: "60min",
                    Misteret: "16",
                },
            },
            longDesc: {
                alb: `Një grup fëmijësh shumë të apasionuar për shfaqjet e cirkut. Fatkeqësisht, një natë më parë një nga bashkëmoshatarët tuaj u rrëmbye. Ndërsa po kërkoni prova, gjetët një biletë të prerë nga një cirk që nuk kishit dëgjuar më parë, "Cirk Wanderlust, 1940". Pa dyshim, vendosët të kërkoni mikun tuaj në vendin e cirkut dhe megjithëse arritët të hyni brenda, kuptuat se ishit kapur në një cirk të mallkuar. Hijet dhe figura të errëta shfaqen në mure duke rrihur midis vija të kuqe dhe të bardha. Nuk e di si, por duhet të jeni të shpejtë dhe të gjeni një mënyrë për të dalë para se palloshi të kthehet.`,
               
                eng: `A group of children very passionate about circus performances. Unfortunately, the night before one of your peers was kidnapped. While searching for evidence, you find a ticket stub from a circus you've never heard of before, "Wanderlust Circus, 1940." Sure enough, you decided to look for your friend at the circus venue, and though you managed to get inside, you realized you were trapped in a cursed circus. Shadows and dark figures appear on the walls, beating between red and white stripes. I don't know how, but you have to be quick and find a way out before the peacock comes back.`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/ERArtwork-11.svg',
            imgSrc: constants.imgPath + "escaperoommansion21.jpg",
            title: {
                eng: "HAUNTED MANSION",
                alb: "HAUNTED MANSION",
                referenc: escapeRoom2
            },
            mainColor: '',
            desc: {
                alb: "Një dhomë inovative horror me atmosferë intensive dhe unike. Kombinimi i efekteve vizuale, zanore dhe kërcitëse, ju ofron një përvojë më të mirë të lojës.",
                eng: "An innovative horror room with intensive & unique atmosphere. Combination of visual, sound & jump-scare effects, offer players an ultimate gaming experience.",
            },
            list: {
                eng: {
                    Category: "Adventure",
                    "Player No.": "2-6",
                    Time: "60min",
                    // Mistery: "17",
                    Mysteries: "16",
                },
                alb: {
                    Kategoria: "Horror",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    // Mister: "16",
                    Misteret: "16",
                },
            },
            longDesc: {
                alb: `Ju jeni "hetuesit më me përvojë të të mbinatyrshmes". Ekipi juaj u afrua nga një burrë misterioz, i cili ju ofroi një shumë të madhe parash, për t'u futur brenda një rezidence specifike dhe për të kërkuar një objekt të fuqishëm të lashtë. Ai me mjeshtëri shmangu t'ju informonte për të gjithë hetuesit e mëparshëm që kishte punësuar, që nuk u kthyen më...`,
                eng: `You are the most experienced “investigators of the supernatural”. Your team was approached by a mysterious man who offered you a huge amount of money, to get inside a specific Mansion and search for a powerful ancient Artifact. He artfully avoided informing you about all the previous investigators he had hired, that never came back…`,
            },
        },
        {
            logo: 'https://hyper-active.com/images/content/ERArtwork-8.svg',
            imgSrc: constants.imgPath + "game_over_1001_nights3.jpg",
            title: {
                eng: "ONE THOUSAND & ONE NIGHTS",
                alb: "ONE THOUSAND & ONE NIGHTS",
                referenc: escapeRoom4
            },
    
            desc: {
                eng: "Become part of a cinematic experience, exploring the secrets of an Eastern kingdom. The immersive experience of this game, along with innovative wow factors and mysteries, is incredibly captivating for all ages!",
                alb: "Bëhuni pjesë e një eksperience kinematografike, duke eksploruar sekretet e një mbretërie të Lindjes. Përvoja zhytëse e kësaj loje, si dhe faktorët wow dhe misteret inovative, do të jenë jashtëzakonisht të tërheqëse nga të gjitha moshat!",
            },
            list: {
                eng: {
                    Category: "Adventure",
                    "Player No.": "2-6",
                    Time: "60min",
                    // Mistery: "15",
                    Mysteries: "15",
                },
                alb: {
                    Kategoria: "Aventurë",
                    "Numri i lojtarëve.": "2-6",
                    Kohëzgjata: "60min",
                    // Mister: "15",
                    Misteret: "15",
                },
            },
            longDesc: {
                eng: `Ju, banorë të guximshëm të Abbasidit, tani është koha për të treguar besnikërinë tuaj dhe për të shpëtuar kalifatin tonë nga Khalifi i Errët dhe plani i tij i keq. Ai tashmë arrin të pushtojë Kalifatët fqinjë dhe të mërgojë Sulltanin tonë. Hapi i tij i ardhshëm është të shkatërrojë tokën dhe popullin tonë, të dominojë zonën dhe të krijojë një Kalifat të ri që do të sundohet nën pushtetin e frikës. Ju jeni i vetmi që mund t'i rezistoni dhe mundeni dhe të shpëtoni tokën tonë nga kjo fatkeqësi e ardhshme.`,
                alb: `You, brave inhabitants of Abbasid, now is the time to show your loyalty and save our caliphate from the Dark Khalif and his evil plan. He already succeeds conquer the neighbour Caliphates and exile our Sultan. His next step is to destroy our land and our people, dominate the area and create a new Caliphate that will be ruled under the power of fear. You are the only one who can resist and defeat him and save our land from this upcoming disaster.`,
            },
        },
    ];

    const color = ["#FAA000", "#F03C44", "#FAA000", '#F03C44', '#2D8CFF', '#FAA000', '#321EFF', '#F03C46', '#FAA000', '#AF46FA'];
    const img = [];

    for (let i = 1; i < 20; i++) {
        img.push("/escape/" + i + ".png");
    }

    // const preparation = {
    //     eng: {
    //         preparation: [
    //             {
    //                 title: "Game Type",
    //                 desc: "You have 4 interactive rooms of the latest generation to choose from. Each room takes you to a different theme. Depending on your age and preferences, choose a story that is suitable for you and that you want to explore.",
    //                 diplay: () => reverse(show1, setShow1),
    //                 state: show1
    //             },
    //             {
    //                 title: "The Game",
    //                 desc: "An automated self-help system is available in each room, which provides suggestions to help maintain the room. We recommend that you use the system to increase your chances of being saved.",
    //                 diplay: () => reverse(show2, setShow2),
    //                 state: show2
    //             },
    //             {
    //                 title: "Players",
    //                 desc: "The escape rooms can be visited by adults and children from the age of 10. For children up to the age of 12, the presence of parents is mandatory. Each room can be played by 2 to a maximum of 6 players at a time.",
    //                 diplay: () => reverse(show3, setShow3),
    //                 state: show3
    //             },
    //             {
    //                 title: "Time",
    //                 desc: "You have 60 minutes to escape the room. After the time is up, your game will be over. Will you and your team manage to escape in time?",
    //                 diplay: () => reverse(show4, setShow4),
    //                 state: show4
    //             },
    //         ],
    //     },
    //     alb: {
    //         preparation: [
    //             {
    //                 title: "Lloji I lojës",
    //                 desc: "Ju keni 4 dhoma interaktive të gjeneratës më të re për të zgjedhur. Çdo dhomë ju çon në një temë të ndryshme. Në varësi të moshës dhe preferencave tuaja, zgjidhni një histori që është e përshtatshme për ju dhe që dëshironi të eksploroni.",
    //                 diplay: () => reverse(show1, setShow1),
    //                 state: show1
    //             },
    //             {
    //                 title: "Loja",
    //                 desc: "Një sistem i automatizuar vetë-ndihme është i disponueshëm në çdo dhomë, i cili jep sugjerime për të ndihmuar në ruajtjen e dhomës. Ne ju rekomandojmë që të përdorni sistemin për të rritur shanset tuaja për t'u shpëtuar.",
    //                 diplay: () => reverse(show2, setShow2),
    //                 state: show2
    //             },
    //             {
    //                 title: "Lojëtarët",
    //                 desc: "Dhomat e arratisjes mund të vizitohen nga të rriturit dhe fëmijët nga mosha 10 vjeçare. Për fëmijët deri në moshën 12 vjeç, prania e prindërve është e detyrueshme. Çdo dhomë mund të luhet nga 2 deri në maksimum 6 lojtarë në të njëjtën kohë.",
    //                 diplay: () => reverse(show3, setShow3),
    //                 state: show3
    //             },
    //             {
    //                 title: "Koha",
    //                 desc: "Keni 60 minuta për të ikur nga dhoma. Pas përfundimit të kohës, loja juaj do të përfundojë. A do të arrini ju dhe ekipi juaj të arratiseni në kohë?",
    //                 diplay: () => reverse(show4, setShow4),
    //                 state: show4
    //             },
    //         ],
    //     }
    // }

    const containerSpacing = {
        paddingY: '80px'
    }

    return (
        loading === false ?
            <Box className={`${style.detailsWrapper}`}>
                <Box className="container">
                    {
                        gameData[1] &&
                        <ActivitiesBanner
                            title={gameData[1].body_header}
                            desc={gameData[1].body_desc}
                            image={constants.imgPath + gameData[1].images}
                            linkPrice="karting"
                            vidId="Y2uTFvwpA5g"
                            id={gameData[1].link}
                        />
                    }
                    <ActivitiesInterest interests={interest[language].interest} />

                    <Box
                        className={'bodyWrapper'}
                        sx={{ height: { md: '500px', sm: '416px', xs: '209px' }, position: 'relative' }}
                    >
                        <Box sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0, width: 1, height: 1, backgroundColor: 'transparent' }} />
                        <Videos id={'Y2uTFvwpA5g'} />
                    </Box>
                </Box>
                <Box className={style.wrapper}>
                    {bigCard.map((i, j) => {
                        return (
                            <SingleBanner
                                logo={i.logo}
                                key={j}
                                image={i.imgSrc}
                                title={i.title[language]}
                                desc={i.desc[language]}
                                list={i.list[language]}
                                longDesc={i.longDesc[language]}
                                id={"739733"}
                                color={color[j]}
                                referenc={i.title.referenc}
                            />
                        );
                    })}
                </Box>

                {
                    gameData[6] &&
                    <Box marginTop={10} ref={gallery}>
                        <CarouselGallery gallery={gameData[6][0]['images']} radius={5} />
                    </Box>
                }

                {
                    gameData[9] &&
                    <Box className={["container", style.wrapper]}>
                        <Grid no={"ReverseSingle"}>
                            {gameData[9].map((i, j) => {
                                return <ImageTextCard data={i} key={j} specialStyling={{
                                    backgroundColor: 'transparent'
                                }}
                                    txtContainerStyling={{
                                        paddingTop: {
                                            md: '0 !important',
                                            xs: 'unset'
                                        },
                                        '& h1': {
                                            marginTop: {
                                                md: '0 !important',
                                                xs: 'unset'
                                            }
                                        },
                                        '& p': {
                                            fontSize: '14px'
                                        }
                                    }} />;
                            })}
                        </Grid>
                    </Box>
                }

                <Box className={style.wrapper} sx={{ paddingTop: '50px' }}>
                    <PartyBanner
                        header={lng[language]['hyperBirthdayHeader']}
                        desc={lng[language]['hyperBirthdayDesc']}
                        buttonTxt={lng[language]['hyperBirthdayBtn']}
                        link={"/activities/party"}
                        imageUrl={"https://hyper-active.com/images/escaperoom/banner/birthdayParty.png"}
                        containerStyle={{
                            width: { sm: "454px", xs: '90%' },
                            color: "#FFF",
                            background: "linear-gradient(180deg, rgba(240,60,70,1) 0%, rgba(250,160,0,1) 100%)",
                            padding: 3,
                            borderRadius: "40px",
                            display: "grid",
                            gridTemplateRows: "1fr 70px 60px",
                            rowGap: "20px",
                        }}
                    />
                </Box>

                <Box className="container">
                    <Box sx={containerSpacing} marginTop={'100px'}>
                        <AppBanner
                            header={lng[language]['hyperAppHeader']}
                            desc={lng[language]['hyperAppDesc']}
                            imgUrl={'../images/6582cf3c6d165.webp'}
                        />
                    </Box>
                    {/* <ReserveText id={739733} /> */}
                    <Box paddingBottom={5} sx={containerSpacing}>
                        <Box sx={{'h1':{ textAlign: 'center !important'}}}>
                            <TextWrapper title={lng[language]['gameSuggestion']} />
                        </Box>
                        <RandomCards removeGame='Escape Rooms' />
                    </Box>
                </Box>

                {/* <Gallery images={img} /> */}
            </Box>
            :
            <WaitingLoader open={loading} />
    );
}
export default EscapeRoom;
